import React, { forwardRef } from 'react';
import { Input, InputNumber } from 'antd';

import './style.scss';

const { TextArea } = Input;

/**
 *
 * @param {Object} props
 * @param {('text' | 'text-area' | number)} props.variant
 * @param {('text' | 'email' | 'password' | 'tel')} props.type
 * @param {String} props.placeholder
 * @param {any} props.prefix
 * @param {Number} props.rows
 * @returns
 */

export const PdAntdInput = forwardRef(
  (
    {
      variant = 'text',
      type = 'text',
      placeholder = '',
      rows = 4,
      prefix = '',
      ...props
    },
    ref
  ) => {
    switch (variant) {
      case 'text':
        return (
          <Input
            className="pd-antd-input"
            ref={ref}
            type={type}
            placeholder={placeholder}
            prefix={prefix}
            {...props}
          />
        );
      case 'text-area':
        return (
          <TextArea
            className="pd-antd-text-area"
            ref={ref}
            rows={rows}
            placeholder={placeholder}
            {...props}
          />
        );
      case 'number':
        return <InputNumber />;
      default:
        return <div>INVALID VARIANT</div>;
    }
  }
);

PdAntdInput.displayName = 'PdAntdInput';

export default { PdAntdInput };
