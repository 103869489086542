import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'antd';

function Container({ children = {}, ...props }) {
  return (
    <Row style={{ height: '100%' }} type="flex" justify="center" {...props}>
      <Col span={24} style={{ height: '100%', maxWidth: '85%' }}>
        {children}
      </Col>
    </Row>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Container };
