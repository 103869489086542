import { range } from 'lodash';

import { rupiahFormatter } from 'utils/numberFormatter';
import { currencyParser } from 'utils/numberParser';

export const ENGAGMENT_RING_OPTIONS = {
  cut: {
    1: 'Poor',
    2: 'Fair',
    3: 'Good',
    4: 'Very Good',
    5: 'Excellent',
  },
  color: range('Z'.charCodeAt(0) - 'D'.charCodeAt(0) + 1).reduce(
    (obj, key) => ({
      ...obj,
      [key + 1]: String.fromCharCode('D'.charCodeAt(0) + key),
    }),
    {}
  ),
  clarity: {
    1: 'IF',
    2: 'VVS1',
    3: 'VVS2',
    4: 'VS1',
    5: 'VS2',
    6: 'SI1',
    7: 'SI2',
    8: 'I3',
  },
  shape: ['oval', 'round', 'princess', 'radiant', 'asscher', 'heart', 'pear'],
  styles: ['channel', 'halo', 'pave', 'solitaire', 'three-stone', 'vintage'],
  shopByMetals: ['platinum', 'rose-gold', 'white-gold', 'yellow-gold'],
};

export const ENGAGMENT_RING_VALUES = {
  cut: [1, Object.keys(ENGAGMENT_RING_OPTIONS.cut).length],
  color: [1, Object.keys(ENGAGMENT_RING_OPTIONS.color).length],
  clarity: [1, Object.keys(ENGAGMENT_RING_OPTIONS.clarity).length],
  carat: [1, 100],
  price: [1, 9e10],
  style: undefined,
  shopByMetal: undefined,
};

export const ENGAGMENT_RING = [
  [
    {
      label: 'Style',
      name: 'style',
      type: 'radio',
      options: ENGAGMENT_RING_OPTIONS.styles,
    },
    {
      label: 'Shop by metal',
      name: 'shopByMetal',
      type: 'radio',
      options: ENGAGMENT_RING_OPTIONS.shopByMetals,
    },
  ],
  // * These comment will be used later after client decision.
  // [
  //   {
  //     label: 'Shape',
  //     name: 'shape',
  //     type: 'radio',
  //     options: ENGAGMENT_RING_OPTIONS.shape,
  //   },
  //   {
  //     label: 'Cut',
  //     name: 'cut',
  //     type: 'slider',
  //     options: ENGAGMENT_RING_OPTIONS.cut,
  //   },
  // ],
  // [
  //   {
  //     label: 'Color',
  //     name: 'color',
  //     type: 'slider',
  //     options: ENGAGMENT_RING_OPTIONS.color,
  //   },
  //   {
  //     label: 'Clarity',
  //     name: 'clarity',
  //     type: 'slider',
  //     options: ENGAGMENT_RING_OPTIONS.clarity,
  //   },
  // ],
  [
    // {
    //   label: 'Carrat',
    //   name: 'carat',
    //   type: 'slider-custom',
    // },
    {
      label: 'Price',
      name: 'price',
      type: 'slider-custom',
      formatter: rupiahFormatter,
      parser: currencyParser,
    },
  ],
];

export const WEDDING_RING_OPTIONS = {
  ring: ['classic', 'carved', 'diamond', 'alternative'],
  gender: ['all', 'men', 'women'],
};

export const WEDDING_RING_VALUES = {
  price: [1, 9e10],
};

export const WEDDING_RING = [
  [
    {
      label: 'Choose a rings',
      name: 'ring',
      type: 'radio',
      options: WEDDING_RING_OPTIONS.ring,
    },
    {
      label: 'Choose a Gender',
      name: 'gender',
      type: 'radio',
      options: WEDDING_RING_OPTIONS.gender,
    },
  ],
  [
    {
      label: 'Price Range',
      name: 'price',
      type: 'slider-custom',
      formatter: rupiahFormatter,
      parser: currencyParser,
    },
  ],
];
