import OvalDiamonds from 'assets/img/diamond-ring/oval.png';
import Vector from 'assets/img/diamond-ring/Vector.svg';

import Vector1 from 'assets/img/diamond-ring/Vector1.svg';
import Vector2 from 'assets/img/diamond-ring/Vector2.svg';
import Vector3 from 'assets/img/diamond-ring/Vector3.svg';
import Vector4 from 'assets/img/diamond-ring/Vector4.svg';
import Vector5 from 'assets/img/diamond-ring/Vector5.svg';
import Vector6 from 'assets/img/diamond-ring/Vector6.svg';
import Vector7 from 'assets/img/diamond-ring/Vector7.svg';

import YellowDiamonds from 'assets/img/diamond-ring/yellow.png';
import RedDiamonds from 'assets/img/diamond-ring/red.png';
import PurpleDiamonds from 'assets/img/diamond-ring/purple.png';
import OrangeDiamonds from 'assets/img/diamond-ring/orange.png';

export const diamondColour = [
  {
    img: YellowDiamonds,
    color: 'Yellow',
  },
  {
    img: RedDiamonds,
    color: 'Red',
  },
  {
    img: PurpleDiamonds,
    color: 'Purple',
  },
  {
    img: OrangeDiamonds,
    color: 'Orange',
  },
];

export const diamondData = [
  {
    image: OvalDiamonds,
    name: 'El Elyssium',
    price: 5000000,
  },
  {
    image: OvalDiamonds,
    name: '金指輪',
    price: 25000000,
  },
  {
    image: OvalDiamonds,
    name: 'The Big Value',
    price: 150000000,
  },
  {
    image: OvalDiamonds,
    name: 'Virtue Magnifico',
    price: 25000000,
  },
  {
    image: OvalDiamonds,
    name: 'Cincin Persatuan',
    price: 15000000,
  },
  {
    image: OvalDiamonds,
    name: 'Our Story',
    price: 21000000,
  },
  {
    image: OvalDiamonds,
    name: '結婚式',
    price: 20000000,
  },
  {
    image: OvalDiamonds,
    name: 'El Bellisimo',
    price: 21000000,
  },
  {
    image: OvalDiamonds,
    name: 'Al-Fadheel',
    price: 400000000,
  },
  {
    image: OvalDiamonds,
    name: 'Arcane Picaro',
    price: 24000000,
  },
  {
    image: OvalDiamonds,
    name: 'Koh-i Noor',
    price: 26000000,
  },
  {
    image: OvalDiamonds,
    name: 'Goldy Boldy',
    price: 4000000,
  },
];

export const diamondCut = {
  1: 'Good',
  2: 'Very Good',
  3: 'Ideal',
  4: 'True Hearts',
};
export const diamondColor = {
  1: 'M',
  2: 'L',
  3: 'K',
  4: 'J',
  5: 'H',
  6: 'I',
  7: 'G',
  8: 'F',
  9: 'E',
};
export const diamondClarity = {
  1: 'I2',
  2: 'SI2',
  3: 'SI1',
  4: 'VS2',
  5: 'VS1',
  6: 'VVS2',
  7: 'VVS1',
  8: 'IF',
  9: 'FL',
};

export const diamondTypes = [
  {
    img: Vector,
    name: 'oval',
  },
  {
    img: Vector1,
    name: 'curved',
  },
  {
    img: Vector2,
    name: 'love',
  },
  {
    img: Vector3,
    name: 'star',
  },
  {
    img: Vector4,
    name: 'circle',
  },
  {
    img: Vector5,
    name: 'rectangle',
  },
  {
    img: Vector6,
    name: 'triangle',
  },
  {
    img: Vector7,
    name: 'trapesium',
  },
];
