import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

import { PdTitle } from 'components/pd-title';
import { Button } from 'components/button';
import handleError from 'utils/handleError';
import { TOP_BANNER_DIAMOND } from 'utils/constant';

import './style.scss';

export default function TopBanner() {
  function handleErrorVideo() {
    handleError({ response: { message: "Can't load video" } });
  }

  return (
    <Row type="flex" align="middle" className="top-banner-container">
      <Col span={24} md={14} className="left-side">
        <PdTitle
          align="center"
          color="inherit"
          size="large"
          className="text-color-primary">
          {TOP_BANNER_DIAMOND.title}
        </PdTitle>
        <PdTitle
          align="center"
          color="inherit"
          size="small"
          className="text-color-primary">
          <p>{TOP_BANNER_DIAMOND.subtitle_1}</p>
          <p>A{TOP_BANNER_DIAMOND.subtitle_2}</p>
          <Link to="/diamonds-shapes">
            <Button variant="dark" size="extra-small">
              Find your perfect diamond
            </Button>
          </Link>
        </PdTitle>
      </Col>
      <Col span={24} md={10} align="right" className="right-side">
        <div className="video-container">
          <video loop autoPlay muted>
            <source
              src="https://elisee.s3.us-west-2.amazonaws.com/diamond.mp4"
              type="video/mp4"
              onError={handleErrorVideo}
            />
          </video>
        </div>
      </Col>
    </Row>
  );
}
