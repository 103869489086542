import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Col, Row } from 'antd';
import Icon from '@ant-design/icons';

import { Button } from 'components/button';
import './style.scss';

function MobileMenu(props) {
  const { handleClose } = props;

  return (
    <div className="pd-mobile-menu">
      <Row className="header">
        <Col
          className="side-btn pd-align-right"
          xs={{ span: 2, offset: 1 }}
          sm={{ span: 2, offset: 1 }}
          onClick={handleClose}>
          <Icon type="close" />
        </Col>
        <Col xs={24} sm={24} className="title">
          Menu
        </Col>
      </Row>
      <Row>
        <Col span={20} className="mobile-menu">
          <Link to={'/'} onClick={handleClose}>
            <div className="menu">Home</div>
          </Link>
          <Link to="/product/engagement-rings" onClick={handleClose}>
            <div className="menu">Engagement Rings</div>
          </Link>
          <Link to="/product/wedding-rings" onClick={handleClose}>
            <div className="menu">Wedding Rings</div>
          </Link>
          <Link to="/diamonds" onClick={handleClose}>
            <div className="menu">Diamonds</div>
          </Link>
          <Link to="/education" onClick={handleClose}>
            <div className="menu">Education</div>
          </Link>
          <Link to={'/about'} onClick={handleClose}>
            <div className="menu">About</div>
          </Link>
          <Link to={'/contact'} onClick={handleClose}>
            <div className="menu">Contact</div>
          </Link>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Button onClick={handleClose}>Close</Button>
      </Row>
    </div>
  );
}

MobileMenu.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default MobileMenu;
