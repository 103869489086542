import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';

import { Button } from 'components/button';

import './style.scss';

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="pd-footer">
      <Row type="flex" justify="space-around">
        <Col flex="auto" xs={24} lg={{ span: 4, offset: 2 }}>
          <p className="title">Product</p>
          <ul className="list">
            <li>
              <Link to="/product/engagement-rings">Engagement Rings</Link>
            </li>
            <li>
              <Link to="/product/wedding-rings">Wedding Rings</Link>
            </li>
            <li>
              <Link to="/diamonds">Diamonds</Link>
            </li>
          </ul>
        </Col>
        <Col flex="auto" xs={24} lg={4}>
          <p className="title">Elisee</p>
          <ul className="list">
            <li>
              <Link to="/education">Education</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </Col>
        <Col flex="auto" xs={0} lg={4}>
          <p className="title">Social Media</p>
          <ul className="list">
            <li>
              <a
                href="https://instagram.com/eliseeofficial"
                rel="noopener noreferrer"
                target="_blank">
                Instagram
              </a>
            </li>
          </ul>
        </Col>
        <Col xs={24} lg={8}>
          <p className="title custom-design">Do you want custom design?</p>
          <Link to="/contact">
            <Button size="medium">Request Now</Button>
          </Link>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col lg={{ span: 12 }}>
          <div className="pd-footer-center">
            © {currentYear} Élisée | All rights reserved.
          </div>
        </Col>
      </Row>
    </footer>
  );
}

export default { Footer };
