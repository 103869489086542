import React from 'react';
import classNames from 'classnames';

import './style.scss';

export function Button({
  block = false,
  children,
  className = '',
  padding = 'large',
  size = 'small',
  style = {},
  variant = 'dark',
  ...props
}) {
  const combinedClassName = classNames(
    'pd-button',
    variant,
    className,
    `padding-${padding}`,
    size,
    {
      block: block,
    }
  );

  return (
    <button style={style} className={combinedClassName} {...props}>
      {children}
    </button>
  );
}

export default { Button };
