import React from 'react';
import { Breadcrumb } from 'antd';

const { Item } = Breadcrumb;

export function PdBreadcrumbs({ className = '', contents = [] }) {
  return (
    <Breadcrumb className={className}>
      {contents.map((content) => (
        <Item key={content}>{content}</Item>
      ))}
    </Breadcrumb>
  );
}

export default { PdBreadcrumbs };
