import education1 from 'assets/img/education-1.png';
import education2 from 'assets/img/education-2.png';
import educationBanner1 from 'assets/img/education-banner-1.png';
import banner1 from 'assets/img/banner-1.png';
import banner2 from 'assets/img/banner-2.png';
import proposal1 from 'assets/img/proposal-1.jpeg';

export const imageData = [
  {
    original: proposal1,
    thumbnail: proposal1,
  },
  {
    original: education1,
    thumbnail: education1,
  },
  {
    original: education2,
    thumbnail: education2,
  },
  {
    original: banner1,
    thumbnail: banner1,
  },
  {
    original: banner2,
    thumbnail: banner2,
  },
  {
    original: educationBanner1,
    thumbnail: educationBanner1,
  },
];

export const columns = [
  {
    dataIndex: 'title',
    key: 'title',
  },
  {
    dataIndex: 'data',
    key: 'data',
    className: 'Bold',
  },
];

export const reviewData = [
  {
    name: 'Winna',
    job: '',
    prof_pic: 'https://i.imgur.com/MIBaIcA.jpg',
    review: `so happy suka banget sama barangnyaaa :sob::sob: mana dibantuin make sure ukuran cincin lagii so luv, mudah2an ada varian cincin lainnya lagi`,
  },
  {
    name: 'Eddo',
    job: 'Barista',
    prof_pic: 'https://i.imgur.com/rC5e4qt.jpg',
    review: 'barangnya sesuai dgn pesanan. pengiriman jg cepat.',
  },
];

export const detailDiamond = {
  sku: 'SKUIIVV',
  color: 'Gray',
  clarity: 'S1',
  cut: 'Good',
};

export const matchingBandData = [
  {
    name: 'El Elyssium',
    price: 5000000,
  },
  {
    name: '金指輪',
    price: 25000000,
  },
];
