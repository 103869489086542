import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { MenuOutlined, ShoppingOutlined } from '@ant-design/icons';
import { Row, Col, Popover, Typography, Divider } from 'antd';
import { startCase } from 'lodash';

import Logo from 'assets/img/logo/elisee-logo.png';

import MobileMenu from 'components/mobile-menu';
import { Button } from 'components/button';
import { CartContext } from 'contexts/cart';
import { rupiahFormatter } from 'utils/numberFormatter';

import './style.scss';

const { Text } = Typography;

export function Header() {
  const [mobileMenuVisibility, setMobileMenuVisibility] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const { cart } = useContext(CartContext);

  function handleMobileMenuVisibility(isOpen = false) {
    setMobileMenuVisibility(isOpen);
  }

  function PopoverContent({ content }) {
    if (content.length === 0) {
      return <Text>No product in cart</Text>;
    }

    const totalPrice = content.reduce((acc, product) => {
      return acc + +product.price;
    }, 0);

    return (
      <div style={{ width: 250 }} className="popover-content">
        {content.map((product) => {
          const { title = '', price = '', type = '', id = '' } = product;
          return (
            <Row key={`popover-${type}-${id}`}>
              <Col span={6}>Image</Col>
              <Col span={18} className="meta">
                <Text>{startCase(type)}</Text>
                <Text>{startCase(title)}</Text>
                <Text align="right">{rupiahFormatter(+price)}</Text>
              </Col>
            </Row>
          );
        })}
        <Divider />
        <Text align="right">{rupiahFormatter(totalPrice)}</Text>
        <Row type="flex" justify="center" className="pd-margin-top-md">
          <Link to="/order" onClick={() => setShowPopover(false)}>
            <Button variant="primary">Order Now</Button>
          </Link>
        </Row>
      </div>
    );
  }

  return (
    <>
      {mobileMenuVisibility && (
        <MobileMenu handleClose={() => handleMobileMenuVisibility(false)} />
      )}
      <header className="pd-header">
        <div className="pd-header-logo">
          <Link to="/">
            <img src={Logo} alt="ÉLISÉE Logo" height="42" />
          </Link>
        </div>
        <div className="pd-header-hamburger">
          <MenuOutlined
            style={{ fontSize: '20px', color: 'black' }}
            onClick={() => handleMobileMenuVisibility(true)}
          />
        </div>
        <div className="pd-header-items">
          <Link to="/product/engagement-rings">Engagement Rings</Link>
          <Link to="/product/wedding-rings">Wedding Rings</Link>
          <Link to="/diamonds">Diamonds</Link>
          <Link to="/education">Education</Link>
          <Popover
            placement="bottomRight"
            trigger="click"
            visible={showPopover}
            content={<PopoverContent content={cart} />}
            onVisibleChange={setShowPopover}>
            <ShoppingOutlined />
          </Popover>
        </div>
      </header>
    </>
  );
}

export default { Header };
