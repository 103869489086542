/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Pagination, Row, Select, Spin, Typography } from 'antd';
import { get, startCase } from 'lodash';

import RingAPI from 'api/ring';

import RingPlaceholder from 'assets/img/ring_placeholder.png';
import Ring from 'assets/img/ring.png';
import RingNoDiamond from 'assets/img/ring-no-diamond.png';
import RoundDiamond from 'assets/img/diamond-setup.png';

import { Container } from 'components/container';
import { PageSpinner } from 'components/page-spinner';
import { PdBreadcrumbs } from 'components/pd-breadcrumbs';
import { PdProductCard } from 'components/pd-product-card';
import { PdTitle } from 'components/pd-title';

import handleError from 'utils/handleError';
import { rupiahFormatter } from 'utils/numberFormatter';

import FilterField from './filter-field';
import {
  ENGAGMENT_RING,
  ENGAGMENT_RING_VALUES,
  WEDDING_RING,
  WEDDING_RING_VALUES,
} from './field-meta';
import { sortFilter } from './mockData';
import './style.scss';

const { Option } = Select;
const { Text, Title } = Typography;

function Product({ history }) {
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingResult, setLoadingResult] = useState(false);
  const [meta, setMeta] = useState({});
  const [page, setPage] = useState(1);
  const [rings, setRings] = useState([]);
  const [sort, setSort] = useState('');

  const { type: productType = '' } = useParams();

  const fieldValueRef = useRef(
    productType === 'engagement-rings'
      ? { ...ENGAGMENT_RING_VALUES }
      : { ...WEDDING_RING_VALUES }
  );

  function handleSelectSort(value) {
    setSort(value);
  }

  function handleDetailPage(id = '') {
    history.push(`/product/${productType}/${id}`);
  }

  async function fetchRings({ page = 1, limit = 12, payload = {} }) {
    const { cut = [] } = payload;
    const modifiedCut =
      cut.length && [...cut].join(',').toLowerCase().split(',');
    setLoadingResult(true);
    try {
      const { data } = await RingAPI.getRingList({
        limit,
        page,
        payload: { ...payload, cut: modifiedCut },
        productType: productType.replace('-', ''),
        sortBy: sort,
      });
      const { data: ringData = [], meta = {} } = data;
      setMeta(meta);
      setRings(ringData);
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingResult(false);
    }
  }

  async function handleFilterEvent(values) {
    try {
      setPage(1);
      if (values) {
        await fetchRings({ payload: prepareData(values) });
      } else {
        await fetchRings({});
      }
    } catch (error) {
      handleError(error);
    }
  }

  function prepareData(values) {
    if (productType === 'engagement-rings') {
      const {
        // * Hidden until filter shown
        // carat,
        // clarity,
        // color,
        // cut,
        // shape,
        shopByMetal,
        price,
        style,
      } = values;
      return {
        // * Hidden until filter shown
        // min_carat: carat[0],
        // max_carat: carat[1],
        // color: getValues(color, ENGAGMENT_RING_OPTIONS.color),
        // cut: getValues(cut, ENGAGMENT_RING_OPTIONS.cut),
        // clarity: getValues(clarity, ENGAGMENT_RING_OPTIONS.clarity),
        // shape: shape || '',
        max_price: price[1],
        min_price: price[0],
        shopByMetal: shopByMetal ? [shopByMetal] : [],
        style: style ? [style] : [],
      };
    }

    return {
      ...values,
      min_price: values.price[0],
      max_price: values.price[1],
    };
  }

  // * Hidden until filter shown
  // function getValues(filterValue = [], initValue = {}) {
  //   const [min, max] = filterValue;
  //   return range(max - min + 1).map((val) => initValue[min + val]);
  // }

  async function fetchRingsPriceRange() {
    setLoadingPage(true);
    try {
      const {
        data: { highest_price = [], lowest_price = [] },
      } = await RingAPI.getRingsPriceRange({
        productType: productType.replace('-', ''),
      });
      const maxPrice = get(highest_price, ['0', 'price'], 9e10);
      const minPrice = get(lowest_price, ['0', 'price'], 1);
      fieldValueRef.current.price = [+minPrice, +maxPrice];
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    fetchRingsPriceRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productType]);

  useEffect(() => {
    fetchRings({ page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, productType, sort]);

  if (loadingPage) return <PageSpinner />;

  return (
    <div className="pd-product">
      <Container>
        <PdTitle size="medium" font="montserrat" color="black" bold>
          {startCase(productType)}
        </PdTitle>
        <PdBreadcrumbs
          className="pd-product-filter-breadcrumb"
          contents={['Home', startCase(productType)]}
        />

        <FilterField
          className="pd-margin-top-lg pd-margin-bottom-xl"
          onSubmit={handleFilterEvent}
          onClear={handleFilterEvent}
          fields={
            productType.includes('engagement') ? ENGAGMENT_RING : WEDDING_RING
          }
          initialValues={fieldValueRef.current}
        />
      </Container>

      {productType.includes('engagement') && (
        <div className="pd-product-banner">
          <PdTitle size="large" align="center" bold>
            Create your dream ring
          </PdTitle>
          <PdTitle size="small" align="center" color="black">
            {'(In 3 simple steps)'}
          </PdTitle>
          <div className="content">
            <div className="content-card">
              <img className="image" src={RingNoDiamond} alt="ring" />
              <PdTitle size="small" align="center" bold>
                Choose your ring setting
              </PdTitle>
              <p>
                Browse through our various selection of stunning ring styles
              </p>
            </div>
            <div className="operator">+</div>
            <div className="content-card">
              <img className="image" src={RoundDiamond} alt="diamond" />
              <PdTitle size="small" align="center" bold>
                Choose your diamond
              </PdTitle>
              <p>
                Select your desired loose diamond according to your budget and
                the 4C&apos;s
              </p>
            </div>
            <div className="operator">=</div>
            <div className="content-card">
              <img className="image" src={Ring} alt="ring" />
              <PdTitle size="small" align="center" bold>
                Review your dream ring
              </PdTitle>
              <p>Finalize and review your dream ring</p>
            </div>
          </div>
        </div>
      )}

      <Container className="pd-margin-top-sm">
        <Row
          className="pd-margin-bottom-md"
          justify="space-between"
          align="middle"
          type="flex">
          <PdTitle color="black" bold size="small" font="montserrat" level={2}>
            Showing {meta?.total} result
          </PdTitle>
          <div className="pd-product-content-wrapper-filter">
            Sort by
            <Select
              className="pd-margin-left-sm"
              onChange={handleSelectSort}
              placeholder="Choose Filter"
              style={{ width: 126 }}>
              {Object.keys(sortFilter).map((key) => (
                <Option key={key} value={key}>
                  {sortFilter[key]}
                </Option>
              ))}
            </Select>
          </div>
        </Row>
        {loadingResult ? (
          <Row type="flex" justify="center">
            <Spin />
          </Row>
        ) : (
          <Row gutter={[16, 16]}>
            {rings.map((ring) => {
              const {
                id = '',
                price = 0,
                ProductImages: image = [],
                title = '',
              } = ring ?? {};
              return (
                <Col xs={12} lg={6} key={title} className="pd-product-card">
                  <PdProductCard hoverable onClick={() => handleDetailPage(id)}>
                    <div className="pd-product-content-card-image">
                      <img src={image[0]?.url || RingPlaceholder} alt={title} />
                    </div>
                    <Title level={4} className="pd-product-content-card-title">
                      {title}
                    </Title>
                    <Text>{rupiahFormatter(price)}</Text>
                  </PdProductCard>
                </Col>
              );
            })}
          </Row>
        )}

        <Row className="pd-margin-top-md">
          <Pagination
            pageSize={meta?.per_page || 12}
            current={page}
            total={meta?.total || 12}
            onChange={setPage}
          />
        </Row>
      </Container>
    </div>
  );
}

export { Product };
