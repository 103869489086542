import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'antd';

import { Button } from 'components/button';
import { PdModal } from 'components/pd-modal';
import { PdCarousel } from 'components/pd-carousel';
import { PdIntroductionCard } from 'components/pd-introduction-card';
import { PdTitle } from 'components/pd-title';

import Education1 from 'assets/img/education-1.png';
import Education2 from 'assets/img/education-2.png';
import Education3 from 'assets/img/education-3.png';

import './style.scss';

export function Education() {
  const [isModalDiamondlVisible, setIsModalDiamondVisible] = useState(false);
  const [isModalPreciousMetalVisible, setIsModalPreciousMetalVisible] =
    useState(false);
  const history = useHistory();
  const changePage = (page) => {
    history.push(page);
  };

  const ModalDiamondGuide = () => {
    return (
      <PdModal
        footer={null}
        onCancel={setIsModalDiamondVisible.bind(this, false)}
        visible={isModalDiamondlVisible}
        width={1000}>
        <PdTitle level={2} size="extra-medium" className="pd-margin-bottom-md">
          Diamond Guide
        </PdTitle>
        Coming Soon!
      </PdModal>
    );
  };

  const ModalPreciousMetalGuide = () => {
    return (
      <PdModal
        footer={null}
        onCancel={setIsModalPreciousMetalVisible.bind(this, false)}
        visible={isModalPreciousMetalVisible}
        width={1000}>
        <PdTitle level={2} size="extra-medium" className="pd-margin-bottom-md">
          Precious Metals Guide
        </PdTitle>
        Coming Soon!
      </PdModal>
    );
  };

  const contents = [
    <div className="pd-education-carousel first-banner" key={1}>
      <Row type="flex" align="top">
        <Col lg={{ offset: 11, span: 12 }} className="pd-education-wrapper">
          <div className="pd-education-wrapper-banner-title">
            <PdTitle align="center" size="extra-large">
              ENGAGEMENT RING GUIDE
            </PdTitle>
          </div>
          <Button
            variant="dark"
            size="medium"
            onClick={() => changePage('/product/engagement-rings')}>
            Get Inspired
          </Button>
        </Col>
      </Row>
    </div>,
    <div className="pd-education-carousel second-banner" key={2}>
      <Row type="flex" align="top">
        <Col lg={{ offset: 0, span: 10 }} className="pd-education-wrapper">
          <div className="pd-education-wrapper-banner-title">
            <PdTitle align="center" size="extra-large">
              WEDDING RING GUIDE
            </PdTitle>
          </div>
          <Button
            variant="dark"
            size="medium"
            onClick={() => changePage('/product/wedding-rings')}>
            Get Inspired
          </Button>
        </Col>
      </Row>
    </div>,
  ];

  return (
    <div className="pd-education">
      <PdCarousel contents={contents} />
      <Row type="flex" align="middle">
        <Col lg={{ span: 12 }}>
          <img src={Education1} alt="" className="pd-education-image" />
        </Col>
        <PdIntroductionCard position="right">
          <div className="pd-education-wrapper-title">
            <PdTitle align="center" level={2} size="large">
              Diamond Guide
            </PdTitle>
          </div>
          <div className="pd-education-wrapper-text">
            Learn about the most important characteristics to understand when
            making a diamond purchase: The 4C’s
          </div>
          <Button
            variant="dark"
            onClick={setIsModalDiamondVisible.bind(this, true)}>
            Learn More
          </Button>
        </PdIntroductionCard>
      </Row>
      <Row type="flex" align="middle">
        <Col lg={{ span: 12, offset: 12 }}>
          <img src={Education2} alt="" className="pd-education-image" />
        </Col>
        <PdIntroductionCard position="left">
          <div className="pd-education-wrapper-title">
            <PdTitle align="center" level={2} size="large">
              Ring Size Guide
            </PdTitle>
          </div>
          <div className="pd-education-wrapper-text">
            Find your ring size with our ring sizing guide
          </div>
          <a
            href="https://elisee-dev.s3.us-west-2.amazonaws.com/Ring+size+elisee+pdf.pdf"
            target="_blank"
            rel="noopener noreferrer"
            download="ring-size.pdf">
            <Button variant="dark">Download the ring sizing guide</Button>
          </a>
        </PdIntroductionCard>
      </Row>
      <Row type="flex" align="middle">
        <Col lg={{ span: 12 }}>
          <img src={Education3} alt="" className="pd-education-image" />
        </Col>
        <PdIntroductionCard position="right">
          <div className="pd-education-wrapper-title">
            <PdTitle align="center" level={2} size="large">
              Precious Metals Guide
            </PdTitle>
          </div>
          <div className="pd-education-wrapper-text">
            Learn more about the different precious metals available and which
            one suits you the best
          </div>
          <Button
            variant="dark"
            onClick={setIsModalPreciousMetalVisible.bind(this, true)}>
            Find your ideal metal
          </Button>
        </PdIntroductionCard>
      </Row>
      <ModalDiamondGuide />
      <ModalPreciousMetalGuide />
    </div>
  );
}

export default { Education };
