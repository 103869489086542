import React, { useEffect, useState } from 'react';
import { Col, InputNumber, Pagination, Row, Select, Typography } from 'antd';
import { startCase } from 'lodash';

import DiamondApi from 'api/diamond';

import { Button } from 'components/button';
import { PageSpinner } from 'components/page-spinner';
import { PdBreadcrumbs } from 'components/pd-breadcrumbs';
import { PdProductCard } from 'components/pd-product-card';
import { PdRadioButton } from 'components/pd-radio-button';
import { PdSliderRange } from 'components/pd-slider-range';
import { PdTitle } from 'components/pd-title';

import { currencyParser } from 'utils/numberParser';
import { rupiahFormatter } from 'utils/numberFormatter';
import handleError from 'utils/handleError';

import ImagePlaceholder from 'assets/img/image-not-available.png';

import {
  diamondClarity,
  diamondColor,
  diamondCut,
  diamondTypes,
} from './mockData';
import './style.scss';

const { Option } = Select;
const { Text, Title } = Typography;

const defaultPriceRange = [1, 9e10];
export const dotsStyle = {
  height: 0,
  width: 0,
  border: 0,
};

export function DiamondShape({ history, location }) {
  const { state: { shape = '' } = {} } = location;

  const filterDefaultValue = {
    clarity: [1, Object.keys(diamondClarity).length],
    color: [1, Object.keys(diamondColor).length],
    cut: [1, Object.keys(diamondCut).length],
    price: defaultPriceRange,
    shape,
  };

  const slideRangeDefaultValue = {
    price: defaultPriceRange,
  };

  const sortFilter = {
    '': 'Most Suitable',
    best_seller: 'Best Seller',
    price: 'Price',
    name: 'Name',
  };

  const [sort, setSort] = useState('');
  const [cursor, setCursor] = useState(1);
  const [content, setContent] = useState([]);
  const [diamondFilter, setDiamondFilter] = useState(filterDefaultValue);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [slideRange, setSlideRange] = useState(slideRangeDefaultValue);
  const [totalContent, setTotalContent] = useState(0);

  function handleChangeFilter(field) {
    return (value) => {
      setDiamondFilter((prev) => ({ ...prev, [field]: value }));
    };
  }

  function handleChangeSlideRange(value = 0, field, type) {
    if (value !== '') {
      const changedValue =
        type === 'min'
          ? [value, slideRange[field][1]]
          : [slideRange[field][0], value];
      // ! If bugs happened at handleChangeSlideRange, rollback with this function
      // setSlideRange((prev) => ({ ...prev, [field]: changedValue }));
      setDiamondFilter((prev) => ({ ...prev, [field]: changedValue }));
    }
  }

  function handleSelectSort(value) {
    setSort(value);
  }

  function handleDetailPage(id) {
    history.push(`/diamonds-shapes/${id}`);
  }

  function handleOnChangeShape({ target: { value } = {} } = {}) {
    setDiamondFilter((prev) => ({
      ...prev,
      shape: value === 'all' ? '' : value,
    }));
  }

  function handleFilterResult() {
    setCursor(1);
    fetchDiamond();
  }

  function handleClearFilter() {
    setDiamondFilter({
      ...filterDefaultValue,
      shape: '',
    });
    fetchDiamondPriceRange();
  }

  async function fetchDiamond() {
    setLoadingFetch(true);
    try {
      const [min_price, max_price] = diamondFilter.price;

      const payload = {
        ...diamondFilter,
        clarity: diamondFilter.clarity.map(
          (clarity) => diamondClarity[clarity]
        ),
        cut: diamondFilter.cut.map((cut) => diamondCut[cut]),
        color: diamondFilter.color.map((color) => diamondColor[color]),
        max_price,
        min_price,
      };

      const {
        data: { data = [], meta: { total } = {} },
      } = await DiamondApi.getDiamondResult(payload, {
        page: cursor,
        sortBy: sort,
      });
      setContent(data);
      setTotalContent(total);
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingFetch(false);
    }
  }

  async function fetchDiamondPriceRange() {
    try {
      const { data = {} } = await DiamondApi.getDiamondPriceRange();
      const priceProperty = 'total_sales_price_in_currency';
      const priceRange = Object.keys(data).reduce(
        (acc, price) => [...acc, +data[price][0][priceProperty]],
        []
      );

      setDiamondFilter((prev) => ({
        ...prev,
        price: priceRange,
      }));
      setSlideRange((prev) => ({
        ...prev,
        price: priceRange,
      }));
    } catch (error) {
      handleError(error);
    }
  }

  useEffect(() => {
    fetchDiamondPriceRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDiamond();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, sort]);

  return (
    <div className="pd-diamond-shape">
      <div className="pd-diamond-shape-filter">
        <Row>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
            <div className="pd-diamond-shape-filter-title">
              <PdTitle size="medium" font="montserrat" color="black" bold>
                Diamond
              </PdTitle>
            </div>
            <PdBreadcrumbs
              className="pd-diamond-shape-filter-breadcrumb"
              contents={['Home', 'Diamond']}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 10, offset: 2 }}>
            <div>
              <PdTitle
                color="black"
                bold
                size="extra-small"
                font="montserrat"
                level={2}
                className="pd-margin-bottom-sm">
                Shape
              </PdTitle>
            </div>
            <PdRadioButton
              onChange={handleOnChangeShape}
              options={diamondTypes}
              value={diamondFilter.shape === '' ? 'all' : diamondFilter.shape}
            />
          </Col>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 9, offset: 1 }}>
            <div>
              <PdTitle
                color="black"
                bold
                size="extra-small"
                font="montserrat"
                level={2}>
                Color
              </PdTitle>
            </div>
            <PdSliderRange
              className="pd-diamond-shape-slider"
              marks={diamondColor}
              step={null}
              min={1}
              max={Object.keys(diamondColor).length}
              tooltipVisible={false}
              onChange={handleChangeFilter('color')}
              dotStyle={dotsStyle}
              value={diamondFilter.color}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 9, offset: 2 }}>
            <div>
              <PdTitle
                color="black"
                bold
                size="extra-small"
                font="montserrat"
                level={2}>
                Price
              </PdTitle>
            </div>
            <PdSliderRange
              className="pd-diamond-shape-slider"
              defaultValue={defaultPriceRange}
              max={slideRange.price[1]}
              min={slideRange.price[0]}
              onAfterChange={handleChangeFilter('price')}
              onChange={handleChangeFilter('price')}
              step={500000}
              value={diamondFilter.price}
            />
            <Row>
              <Col span={24} className="pd-diamond-shape-col-input-number">
                <InputNumber
                  defaultValue={slideRange.price[0]}
                  max={slideRange.price[1]}
                  min={slideRange.price[0]}
                  onChange={(value) =>
                    handleChangeSlideRange(value, 'price', 'min')
                  }
                  formatter={rupiahFormatter}
                  parser={currencyParser}
                  value={diamondFilter.price[0]}
                />
                <InputNumber
                  defaultValue={slideRange.price[1]}
                  max={slideRange.price[1]}
                  min={slideRange.price[0]}
                  onChange={(value) =>
                    handleChangeSlideRange(value, 'price', 'max')
                  }
                  formatter={rupiahFormatter}
                  parser={currencyParser}
                  value={diamondFilter.price[1]}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 9, offset: 2 }}>
            <div>
              <PdTitle
                color="black"
                bold
                size="extra-small"
                font="montserrat"
                level={2}>
                Clarity
              </PdTitle>
            </div>
            <PdSliderRange
              className="pd-diamond-shape-slider"
              marks={diamondClarity}
              step={null}
              min={1}
              max={Object.keys(diamondClarity).length}
              tooltipVisible={false}
              onChange={handleChangeFilter('clarity')}
              dotStyle={dotsStyle}
              value={diamondFilter.clarity}
            />
          </Col>
        </Row>
        <Row type="flex" justify="center" className="pd-margin-top-lg">
          <Col xs={12} lg={3} className="pd-align-center">
            <Button
              type="primary"
              htmlType="submit"
              className="pd-margin-left-md"
              onClick={handleFilterResult}>
              Filter
            </Button>
          </Col>
          <Col xs={12} lg={3} className="pd-align-center">
            <Button type="primary" onClick={handleClearFilter}>
              Clear
            </Button>
          </Col>
        </Row>
      </div>

      {loadingFetch ? (
        <PageSpinner />
      ) : (
        <div className="">
          <Row type="flex" align="middle">
            <Col xs={{ span: 9, offset: 2 }}>
              <div>
                <PdTitle
                  bold
                  color="black"
                  font="montserrat"
                  level={2}
                  size="small">
                  {`Showing ${totalContent} results`}
                </PdTitle>
              </div>
            </Col>
            <Col xs={{ span: 9, offset: 2 }} hidden={!totalContent}>
              <div className="pd-diamond-shape-content-wrapper-filter">
                <Text className="pd-margin-right-sm">Sort by</Text>
                <Select
                  defaultValue={undefined}
                  placeholder="Choose Filter"
                  onChange={handleSelectSort}
                  value={sort}>
                  {Object.keys(sortFilter).map((key) => (
                    <Option key={key} value={key}>
                      {sortFilter[key]}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>
          <Row className="result-container" gutter={[16, 16]}>
            {content.map(
              ({
                id,
                total_sales_price_in_currency: price = 0,
                image_file_url = '',
                shape = '',
              }) => (
                <Col xs={12} lg={6} key={id} className="pd-product-card">
                  <PdProductCard
                    hoverable
                    onClick={handleDetailPage.bind(this, id)}>
                    <img
                      src={
                        image_file_url !== ''
                          ? image_file_url
                          : ImagePlaceholder
                      }
                      alt="diamond-img"
                      height={200}
                      className="pd-diamond-shape-content-card-image"
                    />
                    <Title
                      level={5}
                      className="pd-diamond-shape-content-card-title">
                      {`${startCase(shape)}-${id}`}
                    </Title>
                    <Text>{rupiahFormatter(price)}</Text>
                  </PdProductCard>
                </Col>
              )
            )}
          </Row>
          {!!totalContent && (
            <Row className="pd-margin-top-md" gutter={10}>
              <Col lg={{ span: 20, offset: 2 }}>
                <Pagination
                  defaultCurrent={cursor}
                  onChange={setCursor}
                  pageSize={12}
                  total={totalContent}
                />
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  );
}

export default { DiamondShape };
