import React from 'react';

import './style.scss';

export function PdIntroductionCard({
  children,
  className = '',
  position = 'right',
}) {
  return (
    <div className={`pd-introduction-card-${position} ${className}`}>
      {children}
    </div>
  );
}

export default { PdIntroductionCard };
