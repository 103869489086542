import Image1 from 'assets/img/proposal/proposal_1.png';
import Image2 from 'assets/img/proposal/proposal_2.png';
import Image3 from 'assets/img/proposal/proposal_3.png';
import Image4 from 'assets/img/proposal/proposal_4.png';
import Image5 from 'assets/img/proposal/proposal_5.png';
import Image6 from 'assets/img/proposal/proposal_6.png';
import Image7 from 'assets/img/proposal/proposal_7.png';
import Image8 from 'assets/img/proposal/proposal_8.png';

export const PROPOSAL_LIST = [
  {
    image: Image1,
    text: 'Cindy Budiman',
  },
  {
    image: Image2,
    text: 'Lia',
  },
  {
    image: Image3,
    text: 'Eva Rinjani',
  },
  {
    image: Image4,
    text: 'Michelle Setio',
  },
  {
    image: Image5,
    text: 'Melina Tjan',
  },
  {
    image: Image6,
    text: 'Gino Pranata',
  },
  {
    image: Image7,
    text: 'Doddy',
  },
  {
    image: Image8,
    text: 'Helen Suhalim',
  },
];
