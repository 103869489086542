import React from 'react';
import { Carousel } from 'antd';

import './style.scss';

export function PdCarousel({ contents = [], className = '' }) {
  return (
    <Carousel autoplay>
      {contents.map((content, idx) => (
        <div className={`pd-carousel ${className}`} key={idx}>
          {content}
        </div>
      ))}
    </Carousel>
  );
}

export default { PdCarousel };
