import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { StoreProvider } from 'store';

import MainContext from './contexts';
import { useScrollToTop } from 'hooks/useScrollToTop';
// CustomerFacing
import { DetailProduct } from 'pages/detail-product';
import { DetailDiamondShape } from 'pages/detail-diamond-shape';
import { DetailDiamondColor } from 'pages/detail-diamond-color';
import { Diamonds } from 'pages/diamonds';
import { DiamondShape } from 'pages/diamond-shape';
import { DiamondColor } from 'pages/diamond-color';
import { Education } from 'pages/education';
import { Product } from 'pages/product';
import { OrderFinish } from 'pages/order-finish';
import { OrderInquiryForm as OrderInquiry } from 'pages/order-inquiry';

import { Home } from 'pages/home';
import { About } from 'pages/about';
import { Careers } from 'pages/careers';
import { ContactForm as Contact } from 'pages/contact';

// CustomerFacing
import { Header } from 'components/header';
import { Footer } from 'components/footer';

import 'antd/dist/antd.css';
import 'App.scss';
import 'config/antd.less';

function CustomerFacingWrapper() {
  useScrollToTop();

  return (
    <MainContext>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/education" component={Education} />
        <Route exact path="/product/:type" component={Product} />
        <Route exact path="/product/:type/:id" component={DetailProduct} />
        <Route
          exact
          path="/diamonds-shapes/:id"
          component={DetailDiamondShape}
        />
        <Route
          exact
          path="/diamonds-colors/:id"
          component={DetailDiamondColor}
        />
        <Route exact path="/diamonds" component={Diamonds} />
        <Route exact path="/diamonds-shapes" component={DiamondShape} />
        <Route exact path="/diamonds-colors" component={DiamondColor} />
        <Route exact path="/order" component={OrderInquiry} />
        <Route exact path="/order/finish" component={OrderFinish} />

        <Route exact path="/about" component={About} />
        <Route exact path="/careers" component={Careers} />
        <Route exact path="/contact" component={Contact} />
      </Switch>
      <Footer />
    </MainContext>
  );
}

function App() {
  return (
    <Router>
      <StoreProvider>
        <Switch>
          <Route path="/" component={CustomerFacingWrapper} />
        </Switch>
      </StoreProvider>
    </Router>
  );
}

export default App;
