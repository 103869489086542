import { api, createAPI } from './index';

export default {
  getLinkUpload({ productType, ext = 'jpg' }) {
    return api.post(`/cms/signed-url`, { type: productType, extension: ext });
  },
  upload({ url, payload }) {
    const uploadAPI = createAPI(url);
    return uploadAPI.put('', payload);
  },
};
