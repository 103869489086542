import education1 from 'assets/img/education-1.png';
import education2 from 'assets/img/education-2.png';
import educationBanner1 from 'assets/img/education-banner-1.png';
import banner1 from 'assets/img/banner-1.png';
import banner2 from 'assets/img/banner-2.png';
import proposal1 from 'assets/img/proposal-1.jpeg';

export const breadcrumbData = [
  'Home',
  'Engagement Rings',
  'Ring Item xxxx',
];

export const imageData = [
  {
    original: proposal1,
    thumbnail: proposal1,
  },
  {
    original: education1,
    thumbnail: education1,
  },
  {
    original: education2,
    thumbnail: education2,
  },
  {
    original: banner1,
    thumbnail: banner1,
  },
  {
    original: banner2,
    thumbnail: banner2,
  },
  {
    original: educationBanner1,
    thumbnail: educationBanner1,
  },
];

export const ringCarratData = Array.from({ length: 10 }, (_, i) => i * 0.5);

export const ringShapeData = ['Round', 'Round?', 'Round!', 'Round....'];

export const metalData = [
  {
    value: '18k_white_gold',
    text: '18K White Gold',
  },
  {
    value: '18k_yellow_gold',
    text: '18K Yellow Gold',
  },
  {
    value: '14k_yellow_gold',
    text: '14K Yellow Gold',
  },
  {
    value: 'platinum_950',
    text: 'Platinum 950',
  },
];

export const fontData = [
  'Garamond',
  'Garamond Bold',
  'Montserrat',
  'Montserrat Bold',
];

export const columns = [
  {
    dataIndex: 'title',
    key: 'title',
  },
  {
    dataIndex: 'data',
    key: 'data',
    className: 'Bold',
  },
];

export const dataSourceRingInfo = [
  {
    key: '1',
    title: 'Metal:',
    data: '18K White Gold',
  },
  {
    key: '2',
    title: 'Width:',
    data: '4mm',
  },
];

export const dataSourceDiamond = [
  {
    key: '1',
    title: 'Shape:',
    data: 'Round',
  },
  {
    key: '2',
    title: 'Quantity:',
    data: '12',
  },
  {
    key: '3',
    title: 'Total Carat (min):',
    data: '0.96',
  },
  {
    key: '4',
    title: 'Average Color:',
    data: 'G-H',
  },
  {
    key: '5',
    title: 'Average Clarity:',
    data: 'SI1-SI2',
  },
];

export const reviewData = [
  {
    name: 'Winna',
    job: '',
    prof_pic: 'https://i.imgur.com/MIBaIcA.jpg',
    review: `so happy suka banget sama barangnyaaa :sob::sob: mana dibantuin make sure ukuran cincin lagii so luv, mudah2an ada varian cincin lainnya lagi`,
  },
  {
    name: 'Eddo',
    job: 'Barista',
    prof_pic: 'https://i.imgur.com/rC5e4qt.jpg',
    review: 'barangnya sesuai dgn pesanan. pengiriman jg cepat.',
  },
];

export const matchingBandData = [
  {
    name: 'El Elyssium',
    price: 5000000,
  },
  {
    name: '金指輪',
    price: 25000000,
  },
];

export const yourDesignMeta = [
  {
    action: 'Choose a ring',
    title: 'Ring Series',
    price: 0,
  },
  {
    action: 'Choose a diamond',
    title: 'Diamond Name',
    price: 0,
  },
];
