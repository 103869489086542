import api from './index';

export default {
  getProductInCart(payload) {
    return api.post('/cart', payload);
  },
  checkout(payload) {
    return api.post('/checkout', payload);
  },
  customOrder(payload) {
    return api.post('/custom_order', payload);
  },
};
