import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Image } from 'antd';

import { PdTitle } from 'components/pd-title';
import { Button } from 'components/button';
import BottomBannerDiamond from 'assets/img/bottom-banner-diamond.gif';
import BottomBannerQuestion from 'assets/img/bottom-banner-question.png';
import { BOTTOM_BANNER_DIAMOND, CONTACTS } from 'utils/constant';

import './style.scss';

export default function BottomBanner() {
  return (
    <Row className="pd-margin-bottom-lg">
      <Col md={12} className="container-left">
        <PdTitle level={3} size="extra-medium" align="center">
          {BOTTOM_BANNER_DIAMOND.left.title}
        </PdTitle>
        <PdTitle
          level={4}
          size="small"
          align="center"
          className="pd-padding-left-xl pd-padding-right-xl pd-margin-top-lg pd-margin-bottom-xl">
          {BOTTOM_BANNER_DIAMOND.left.subtitle}
        </PdTitle>
        <Row type="flex" justify="center">
          <Col md={10}>
            <Link to="/education">
              <Button block variant="dark">
                Get The Tips
              </Button>
            </Link>
          </Col>
        </Row>
        <Image src={BottomBannerDiamond} width={150} preview={false} />
      </Col>
      <Col md={12} className="container-right">
        <PdTitle
          level={2}
          size="large"
          align="center"
          bold
          className="pd-margin-top-sm pd-margin-bottom-md">
          {BOTTOM_BANNER_DIAMOND.right.title}
        </PdTitle>
        <PdTitle level={4} size="small" align="center">
          {BOTTOM_BANNER_DIAMOND.right.subtitle}
        </PdTitle>
        <Row type="flex" align="middle" className="pd-margin-top-xl">
          <Col md={14} className="pd-padding-left-xl">
            <PdTitle className="contact">
              <p>Whatsapp: {CONTACTS.whatsapp}</p>
              <p>Phone: {CONTACTS.phone}</p>
              <p>E-mail: {CONTACTS.email}</p>
            </PdTitle>
          </Col>
          <Col md={10} align="center">
            <Image src={BottomBannerQuestion} width={150} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
