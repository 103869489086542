import React from 'react';
import { Form } from 'antd';

import './style.scss';

const { Item } = Form;

export function PdInlineField({
  label = '',
  labelCol = 12,
  wrapperCol = 12,
  className = '',
  children,
  ...props
}) {
  return (
    <Item
      className={`pd-inline-field ${className}`}
      label={label}
      labelCol={{ span: labelCol }}
      wrapperCol={{ span: wrapperCol }}
      {...props}>
      {children}
    </Item>
  );
}

export default { PdInlineField };
