import React from 'react';
import classNames from 'classnames';
import { Typography } from 'antd';

import './style.scss';

const { Title } = Typography;

/**
 *
 * @param {Object} props
 * @param {('left' | 'center' | 'right')} props.align
 * @param {('primary' | 'secondary' | 'inherit' | 'black')} props.color
 * @param {('garamond' | 'montserrat')} props.font
 * @param {(1 | 2 | 3 | 4)} props.level
 * @param {'double-extra-large' | 'extra-large' | 'large' | 'extra-medium' | 'medium' | 'extra-small' | 'small'} props.size
 * @returns
 */

export function PdTitle({
  align = 'left',
  bold = false,
  className = '',
  color = 'primary',
  font = 'garamond',
  italic = false,
  level = 1,
  size = 'small',
  style = {},
  underline = false,
  children,
  props,
}) {
  return (
    <div className="pd-title">
      <Title
        className={classNames(
          className,
          align,
          { bold },
          color,
          font,
          { italic },
          level,
          size,
          { underline }
        )}
        style={style}
        level={level}
        {...props}>
        {children}
      </Title>
    </div>
  );
}

export default { PdTitle };
