import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Table,
  Typography,
  message,
} from 'antd';
import { startCase, range } from 'lodash';

import RingAPI from 'api/ring';

import RingPlaceholder from 'assets/img/ring_placeholder.png';
import { Button } from 'components/button';
import { Container } from 'components/container';
import { PdBreadcrumbs } from 'components/pd-breadcrumbs';
import { PdGallery } from 'components/pd-gallery';
import { PdInlineField } from 'components/pd-inline-field';
import { PdProductCard } from 'components/pd-product-card';
import { PdReviewCard } from 'components/pd-review-card';
import { PdTitle } from 'components/pd-title';

import { CartContext } from 'contexts/cart';

import handleError from 'utils/handleError';
import { rupiahFormatter } from 'utils/numberFormatter';

import { columns, fontData, reviewData } from './mockData';
import './style.scss';

const { Option } = Select;
const { Text } = Typography;

export function DetailProduct({ history }) {
  const [font, setFont] = useState('Montserrat');
  const [preview, setPreview] = useState('');
  const [ring, setRing] = useState({});
  const { type: productType = '', id = '' } = useParams();
  const { addToCart } = useContext(CartContext);

  function handleChangePrev({ target }) {
    const { value = '' } = target ?? {};
    setPreview(value);
  }

  function handleAddToCart(values) {
    const unvalidKey = validateForm(values);
    if (unvalidKey) {
      message.error(`${startCase(unvalidKey)} field is required`);
      return;
    }
    addToCart({
      ...values,
      text: values.text || '',
      font: values.font || '',
      product_id: id,
      product_type: productType.replace('-', '_'),
    });
    message.success('Product added to cart');
  }

  function validateForm(values) {
    const requiredFields = [
      'shape',
      'carat',
      'metal',
      'ring_size',
      'ring_width',
    ];
    let notValidKey = '';
    const isNotValid = Object.keys(values).some((key) => {
      notValidKey = key;
      return requiredFields.includes(key) && !values[key];
    });

    return isNotValid && notValidKey;
  }

  function handleAddDiamond() {
    history.push('/diamonds');
  }

  async function fetchDetailRing() {
    try {
      const { data } = await RingAPI.getDetailRing({
        productType: productType.replace('-', ''),
        id,
      });
      const ringData = parseAttributeName({ data: data?.ring, productType });
      setRing(ringData);
    } catch (error) {
      handleError(error);
    }
  }

  function parseAttributeName({ data = {}, productType = '' }) {
    const removedPhrase = productType.replace(/(-)|(s)/g, '');
    const container = {};
    Object.keys(data).forEach((key) => {
      if (key.toLowerCase().includes(removedPhrase)) {
        const newAttribute = key.toLowerCase().replace(removedPhrase, '');
        container[newAttribute] = data[key];
        return;
      }

      container[key] = data[key];
    });
    return container;
  }

  function parseImageData(images) {
    return images.map(({ url }) => ({ original: url, thumbnail: url }));
  }

  function parseRingInfo(data) {
    const { metals = [], ring_width = null, max_width = '' } = data;
    const [metal = {}] = metals;
    return [
      {
        key: '1',
        title: 'Metal:',
        data: metal?.metal || 'Metal',
      },
      {
        key: '2',
        title: 'Width:',
        data: `${ring_width || Number(max_width)}mm`,
      },
    ];
  }

  function parseDiamondInfo(data) {
    return Object.keys(data)
      .filter((key) => key.includes('diamond'))
      .map((key, idx) => {
        const attribute = key.replace('diamond_', '');
        return {
          key: idx + 1,
          title: `${startCase(attribute)} :`,
          data: data[key],
        };
      });
  }

  function getRange({ min = 1, max = 2, step = 0.1, decimal = 1 }) {
    const rangeLength = (max - min) / step + 1;
    return range(rangeLength).map((val) => {
      const value = +min + step * val;
      return value.toFixed(decimal);
    });
  }

  useEffect(() => {
    fetchDetailRing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container className="pd-detail-product pd-padding-top-md">
        <div className="pd-detail-product-spec">
          <PdBreadcrumbs
            contents={[
              'Home',
              startCase(productType.replace('-', ' ')),
              ring.title,
            ]}
            className="pd-margin-bottom-md"
          />
          <Row gutter={40}>
            <Col lg={12}>
              <PdGallery images={parseImageData(ring.ProductImages || [])} />
            </Col>
            <Col lg={12} className="pd-detail-product-spec-description">
              <PdTitle bold color="black" font="montserrat" size="medium">
                {ring.title || ''}
              </PdTitle>
              <PdTitle
                bold
                className="pd-margin-top-md pd-margin-bottom-md"
                color="black"
                font="montserrat"
                size="extra-medium">
                {rupiahFormatter(ring.price || 0)}
              </PdTitle>
              <Form onFinish={handleAddToCart}>
                <Row gutter={[10, 10]} className="form-wrapper">
                  {productType.includes('engagement') ? (
                    <>
                      <Col span={17}>
                        <PdInlineField
                          label="Style"
                          name="style"
                          labelCol={8}
                          wrapperCol={16}>
                          <Select style={{ width: '100%' }}>
                            {ring?.styles?.map(({ style }) => (
                              <Option key={style} value={style}>
                                {startCase(style)}
                              </Option>
                            ))}
                          </Select>
                        </PdInlineField>
                      </Col>
                      <Col span={17}>
                        <PdInlineField
                          label="Shop By Metal"
                          name="shopByMetal"
                          labelCol={8}
                          wrapperCol={16}>
                          <Select style={{ width: '100%' }}>
                            {ring?.shopbymetals?.map(({ metal }) => (
                              <Option key={metal} value={metal}>
                                {startCase(metal)}
                              </Option>
                            ))}
                          </Select>
                        </PdInlineField>
                      </Col>
                      <Col xs={12} lg={10}>
                        <PdInlineField
                          label="Shape"
                          name="shape"
                          isRequired
                          labelCol={8}
                          wrapperCol={16}>
                          <Select>
                            {ring?.shapes?.map(({ shape }) => (
                              <Option key={shape} value={shape}>
                                {shape}
                              </Option>
                            ))}
                          </Select>
                        </PdInlineField>
                      </Col>
                      <Col xs={12} lg={7}>
                        <PdInlineField
                          label="Carrat"
                          name="carat"
                          labelCol={12}
                          wrapperCol={12}>
                          <Select style={{ width: '100%' }}>
                            {getRange({
                              min: ring.min_carat,
                              max: ring.max_carat,
                            }).map((carrat) => (
                              <Option key={carrat} value={carrat}>
                                {carrat}
                              </Option>
                            ))}
                          </Select>
                        </PdInlineField>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={8} lg={8}>
                        <PdInlineField
                          label="Size"
                          name="ring_size"
                          labelCol={8}
                          wrapperCol={16}>
                          <Select>
                            {ring?.sizes?.map(({ size }) => (
                              <Option key={size} value={size}>
                                {size}
                              </Option>
                            ))}
                          </Select>
                        </PdInlineField>
                      </Col>
                      <Col xs={8} lg={9}>
                        <PdInlineField
                          label="Width"
                          name="ring_width"
                          labelCol={12}
                          wrapperCol={12}>
                          <Select style={{ width: '100%' }}>
                            {getRange({
                              min: ring.min_width,
                              max: ring.max_width,
                              step: 1,
                              decimal: 0,
                            }).map((width) => (
                              <Option key={width} value={width}>
                                {width}mm
                              </Option>
                            ))}
                          </Select>
                        </PdInlineField>
                      </Col>
                    </>
                  )}
                  <Col span={17}>
                    <PdInlineField
                      label="Metal"
                      name="metal"
                      labelCol={5}
                      wrapperCol={19}>
                      <Select style={{ width: '100%' }}>
                        {ring?.metals?.map(({ metal }) => (
                          <Option key={metal} value={metal}>
                            {startCase(metal)}
                          </Option>
                        ))}
                      </Select>
                    </PdInlineField>
                  </Col>
                  <Col span={24}>
                    <PdTitle level={2} font="montserrat" bold size="small">
                      Engraving Feature
                    </PdTitle>
                  </Col>
                  <Col span={17}>
                    <PdInlineField
                      label="Text"
                      name="text"
                      labelCol={5}
                      wrapperCol={19}>
                      <Input
                        autoComplete="off"
                        maxLength={16}
                        onChange={handleChangePrev}
                        style={{ width: '172px', padding: 4 }}
                      />
                    </PdInlineField>
                    <Text className="form-item">Max 16 characters</Text>
                  </Col>
                  <Col span={17}>
                    <PdInlineField
                      label="Font"
                      name="font"
                      labelCol={5}
                      wrapperCol={19}>
                      <Select style={{ width: '100%' }} onSelect={setFont}>
                        {fontData.map((font) => (
                          <Option key={font} value={font}>
                            {font}
                          </Option>
                        ))}
                      </Select>
                    </PdInlineField>
                  </Col>
                  <Col span={24}>
                    <PdTitle
                      level={2}
                      font="montserrat"
                      bold
                      size="small"
                      color="black">
                      Preview
                    </PdTitle>
                  </Col>
                  <Col span={24} style={{ padding: 0 }}>
                    <PdTitle level={2} className={`title ${font}`}>
                      {preview || <div style={{ marginBottom: 37 }}></div>}
                    </PdTitle>
                  </Col>
                  <Col span={17}>
                    <Button
                      type="submit"
                      block
                      variant="primary"
                      className="pd-margin-bottom-md">
                      Add to Cart
                    </Button>
                    {productType.includes('engagement') && (
                      <Button
                        block
                        variant="primary"
                        onClick={handleAddDiamond}>
                        Add Diamond
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>

        {/* specification */}
        <Row gutter={16} className="pd-detail-product-desc">
          <Col span={24}>
            <PdTitle font="montserrat" color="black" bold size="medium">
              Product Description
            </PdTitle>
          </Col>
          <Col span={24} className="pd-margin-top-md pd-margin-bottom-sm">
            <Text>{ring.description}</Text>
          </Col>
          <Col span={10}>
            <PdTitle
              level={2}
              font="montserrat"
              bold
              size="small"
              color="black">
              Ring Information
            </PdTitle>
            <Table
              columns={columns}
              dataSource={parseRingInfo(ring)}
              pagination={false}
              showHeader={false}
              className="table"
            />
          </Col>
          <Col span={10}>
            <PdTitle
              level={2}
              font="montserrat"
              bold
              size="small"
              color="black">
              Diamond
            </PdTitle>
            <Table
              columns={columns}
              dataSource={parseDiamondInfo(ring)}
              pagination={false}
              showHeader={false}
              className="table"
            />
          </Col>
        </Row>

        {/* matching bands */}
        <Row className="pd-detail-product-review pd-margin-top-lg">
          <PdTitle
            font="montserrat"
            color="black"
            bold
            size="medium"
            className="pd-margin-bottom-md">
            {productType.includes('engagement')
              ? 'Matching Bands'
              : 'Similar Items'}
          </PdTitle>
          {ring.related_product ? (
            ring?.related_product?.map((ring, idx) => {
              const {
                title = '',
                price = 0,
                ProductImages: images = [],
              } = ring ?? {};
              return (
                <Col
                  lg={5}
                  offset={!idx ? 2 : 0}
                  key={title}
                  className="pd-product-card">
                  <PdProductCard hoverable>
                    <img
                      src={images[0].url}
                      alt={title}
                      className="pd-matching-card-image"
                    />
                    <PdTitle
                      align="center"
                      bold
                      color="black"
                      level={4}
                      className="pd-matching-card-title">
                      {title}
                    </PdTitle>
                    <Text>{rupiahFormatter(price)}</Text>
                  </PdProductCard>
                </Col>
              );
            })
          ) : (
            <Col span={24}>
              <Text>No items found</Text>
            </Col>
          )}
        </Row>
        <Row gutter={16}></Row>
      </Container>

      {/* banner section */}
      {productType.includes('engagement') && (
        <div className="pd-detail-product-banner">
          <Container>
            <Row type="flex" align="middle" justify="space-between">
              <Col span={16}>
                <PdTitle
                  bold
                  className="pd-margin-bottom-md"
                  color="black"
                  level={1}
                  size="extra-medium">
                  Ring Size
                </PdTitle>
                <PdTitle level={3} type="secondary">
                  Find your ring size with our ring sizing guide on this
                  document
                </PdTitle>
                <a
                  href="https://elisee-dev.s3.us-west-2.amazonaws.com/Ring+size+elisee+pdf.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  download="ring-size.pdf">
                  <div className="pdf-button">PDF</div>
                </a>
              </Col>
              <Col span={4}>
                <img
                  src={RingPlaceholder}
                  style={{ maxWidth: '100%' }}
                  alt=""
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}

      {/* review section */}
      <Container className="pd-margin-top-lg">
        <PdTitle font="montserrat" color="black" bold size="medium">
          Review
        </PdTitle>
        <Row>
          <Col span={24}>
            {reviewData.map((data, idx) => {
              const {
                prof_pic = '',
                job = '',
                name = '',
                review = '',
              } = data ?? {};
              return (
                <PdReviewCard
                  avatarSize={120}
                  responsiveAvatar={{
                    xs: 128 * 0.6,
                    md: 128,
                    lg: 128,
                    xl: 128,
                    xxl: 128,
                  }}
                  key={idx}
                  prof_pic={prof_pic}
                  job={job}
                  name={name}
                  review={review}
                />
              );
            })}
          </Col>
        </Row>
      </Container>
    </>
  );
}
