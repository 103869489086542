/**
 *
 * @param {Number} val
 * @example carratFormatter(5)
 */

export function carratFormatter(val) {
  return `${val}K`;
}

/**
 *
 * @param {Number} val
 * @example currencyFormatter(100000)
 */

export function rupiahFormatter(val) {
  return `Rp.${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
