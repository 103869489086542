import React from 'react';
import { Card } from 'antd';

import './style.scss';

export function PdProductCard({
  className = '',
  children,
  style = {},
  ...props
}) {
  return (
    <Card className={`pd-product-card ${className}`} style={style} {...props}>
      {children}
    </Card>
  );
}

export default { PdProductCard };
