import React from 'react';
import { Section } from 'components/section';

import './style.scss';

export function Careers() {
  return <Section title="Careers" />;
}

export default { Careers };
