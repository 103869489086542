import React from 'react';
import ImageGallery from 'react-image-gallery';

import './style.scss';

export function PdGallery({ images = [], className = '' }) {
  return (
    <ImageGallery additionalClass={`pd-gallery ${className}`} items={images} />
  );
}

export default { PdGallery };
