import React from 'react';
import { Col, Form, Row } from 'antd';
import { identity } from 'lodash';

import { PdSliderRange } from 'components/pd-slider-range';
import { PdTitle } from 'components/pd-title';
import { PdRadioButton } from 'components/pd-radio-button';
import { Button } from 'components/button';

import CustomSlider from './custom-slider';
import './style.scss';

export default function FilterField({
  className = '',
  fields = [],
  initialValues = {},
  onSubmit = identity,
  onClear = identity,
  ...props
}) {
  const [form] = Form.useForm();

  function LabelField(label) {
    return (
      <PdTitle color="black" bold size="small" font="montserrat" level={2}>
        {label}
      </PdTitle>
    );
  }

  function renderField(field) {
    if (Array.isArray(field)) {
      return field.map((colField) => (
        <Col xs={24} lg={10} key={colField.name}>
          {renderField(colField)}
        </Col>
      ));
    }

    const {
      label = '',
      name = '',
      type = '',
      options = [],
      formatter = identity,
      parser = identity,
    } = field;
    let formField = '';
    switch (type) {
      case 'radio':
        formField = <PdRadioButton options={options} />;
        break;
      case 'slider-custom':
        formField = <CustomSlider formatter={formatter} parser={parser} />;
        break;
      default:
        formField = (
          <PdSliderRange
            marks={options}
            step={null}
            min={1}
            max={Object.keys(options).length}
            tooltipVisible={false}
            dotStyle={{
              height: 0,
              width: 0,
              border: 0,
            }}
          />
        );
        break;
    }

    return (
      <Form.Item label={LabelField(label)} name={name} colon={false}>
        {formField}
      </Form.Item>
    );
  }

  function handleClear() {
    form.setFieldsValue(initialValues);
    onClear();
  }

  return (
    <Form
      className={`pd-field-filter ${className}`}
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={onSubmit}
      {...props}>
      {fields.map((field, idx) => (
        <Row type="flex" justify="space-between" key={`row-${idx}`}>
          {renderField(field)}
        </Row>
      ))}
      <Row type="flex" justify="center" className="pd-margin-top-md">
        <Col xs={12} lg={3} className="pd-align-center">
          <Button
            type="primary"
            htmlType="submit"
            className="pd-margin-left-md">
            Filter
          </Button>
        </Col>
        <Col xs={12} lg={3} className="pd-align-center">
          <Button type="primary" onClick={handleClear}>
            Clear
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
