import React from 'react';
import { Section } from 'components/section';

import './style.scss';

export function About() {
  return <Section title="About Us" subtitle="Detailed version." />;
}

export default { About };
