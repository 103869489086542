import React, { useEffect, useState, useContext } from 'react';

import { Col, Form, Row, Typography, Table } from 'antd';
import {
  DeleteOutlined,
  LoadingOutlined,
  MailFilled,
  PhoneFilled,
  UserOutlined,
} from '@ant-design/icons';

import TransactionAPI from 'api/transaction';

import { Button } from 'components/button';
import { PdAntdInput } from 'components/pd-antd-input';
import { PdTitle } from 'components/pd-title';

import { CartContext } from 'contexts/cart';

import handleError from 'utils/handleError';
import { ORDER_THUMBNAIL_COLUMN } from 'utils/constant';
import { rupiahFormatter } from 'utils/numberFormatter';

import './style.scss';

const FORM_FIELD = {
  NAME: 'name',
  EMAIL: 'email',
  PHONE_NUMBER: 'phone_number',
  NOTES: 'notes',
};

const { Text, Paragraph } = Typography;

export function OrderInquiryForm({ history }) {
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { cart, removeFromCart, clearCart } = useContext(CartContext);

  const dataColumn = [
    {
      key: 'product_id',
      render: ColumnPic,
    },
    {
      dataIndex: 'title',
      key: 'title',
    },

    {
      dataIndex: 'price',
      key: 'price',
      render: ColumnPrice,
    },
    {
      key: 'action',
      render: ColumnAction(),
    },
  ];

  const dataColumnResponsive = [
    {
      key: 'product_id',
      render: ColumnPic,
    },
    {
      key: 'title',
      render: ColumnTitlePrice,
    },
    {
      key: 'action',
      render: ColumnAction('mobile'),
    },
  ];

  function ColumnAction(view = 'desktop') {
    return function (data = {}) {
      const { id = '', type = '' } = data;
      if (view === 'mobile') {
        return (
          <DeleteOutlined onClick={removeFromCart.bind(this, { id, type })} />
        );
      }

      return (
        <DeleteOutlined
          className="table-action"
          onClick={removeFromCart.bind(this, { id, type })}
        />
      );
    };
  }

  function ColumnTitlePrice(data = {}) {
    const { title = '', price = '' } = data;
    return (
      <Row gutter={[6, 6]}>
        <Col span={24}>
          <Text>{title}</Text>
        </Col>
        <Col>
          <Text style={{ fontWeight: 'bold' }}>{rupiahFormatter(price)}</Text>
        </Col>
      </Row>
    );
  }

  function sumTotal(cart = []) {
    const sum = cart.reduce((total, val) => total + +val.price, 0);

    setTotal(sum);
  }

  function ColumnPic({ type } = {}) {
    return <Text>{ORDER_THUMBNAIL_COLUMN[type]}</Text>;
  }

  function ColumnPrice(price = '') {
    return (
      <Row type="flex" justify="end">
        <Text className="bold">{rupiahFormatter(price)}</Text>
      </Row>
    );
  }

  async function handleSubmit(values) {
    try {
      setIsLoading(true);
      const orders = JSON.parse(localStorage.getItem('cart')).map(
        (product = {}) => {
          const { product_type = '' } = product;

          // remove letter s in product_type at the last sentence
          let modifiedType = product_type.split('');
          modifiedType.pop();
          modifiedType = modifiedType.join('');

          // delete id for diamonds type
          if (product_type === 'diamonds') delete product.product_id;
          return {
            ...product,
            product_type: modifiedType,
          };
        }
      );
      if (cart.length) {
        await TransactionAPI.checkout({
          ...values,
          orders,
        });
        history.push('/order/finish');
      }
      clearCart();
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    sumTotal(cart);
  }, [cart]);

  return (
    <div className="pd-order-inquiry">
      <Row gutter={16} className="pd-order-inquiry-design" justify="center">
        <Col flex="auto" xs={20} lg={12}>
          <div className="title">
            <PdTitle size="large" color="black" bold align="center">
              Order Inquiry
            </PdTitle>
          </div>
          <div className="pd-order-inquiry-design-wrapper">
            <div className="subtitle">
              <PdTitle level={2} font="montserrat" bold size="small">
                Your design
              </PdTitle>
            </div>
            <Row>
              <Col flex="auto" xs={0} lg={24}>
                <Table
                  className="table"
                  dataSource={cart}
                  columns={dataColumn}
                  pagination={false}
                  showHeader={false}
                />
              </Col>
              <Col flex="auto" lg={0}>
                <Table
                  className="table"
                  dataSource={cart}
                  columns={dataColumnResponsive}
                  pagination={false}
                  showHeader={false}
                />
              </Col>
            </Row>
            <Row gutter={16} className="total">
              <Col lg={{ span: 6, offset: 12 }}>
                <Text>Total Price</Text>
              </Col>
              <Col lg={{ span: 6 }}>
                <Text className="bold">{rupiahFormatter(total)}</Text>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="pd-order-inquiry-form">
        <Form onFinish={handleSubmit}>
          <div className="pd-order-inquiry-form-contact">
            <Row gutter={16} type="flex" justify="center">
              <Col xs={20} lg={4}>
                <Form.Item
                  name={FORM_FIELD.NAME}
                  rules={[
                    { message: 'Please insert your name!', required: true },
                  ]}>
                  <PdAntdInput
                    autoComplete="off"
                    placeholder="Name"
                    prefix={<UserOutlined />}
                  />
                </Form.Item>
                <Text type="danger">*Required</Text>
              </Col>
              <Col xs={20} lg={4}>
                <Form.Item
                  name={FORM_FIELD.EMAIL}
                  rules={[
                    { message: 'Please insert your email!', required: true },
                  ]}>
                  <PdAntdInput
                    autoComplete="off"
                    type="email"
                    placeholder="Email"
                    prefix={<MailFilled />}
                  />
                </Form.Item>
                <Text type="danger">*Required</Text>
              </Col>
              <Col xs={20} lg={4}>
                <Form.Item
                  name={FORM_FIELD.PHONE_NUMBER}
                  rules={[
                    {
                      message: 'Please insert your phone number!',
                      required: true,
                    },
                  ]}>
                  <PdAntdInput
                    autoComplete="off"
                    type="tel"
                    placeholder="Phone Number"
                    prefix={<PhoneFilled />}
                  />
                </Form.Item>
                <Text type="danger">*Required</Text>
              </Col>
            </Row>
          </div>
          <div className="pd-order-inquiry-form-description pd-margin-top-lg">
            <Row type="flex" gutter={16} justify="center">
              <Col xs={2} lg={12}>
                <Form.Item name={FORM_FIELD.NOTES}>
                  <PdAntdInput
                    autoComplete="off"
                    variant="text-area"
                    rows={4}
                    placeholder="Write something..."
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="pd-order-inquiry-form-button pd-margin-top-lg">
            <Row type="flex" gutter={16} justify="center">
              <Button loading htmlType="submit">
                {isLoading && <LoadingOutlined />} Submit
              </Button>
            </Row>
          </div>
        </Form>
      </div>
      <div className="pd-order-inquiry-term">
        <Row gutter={16}>
          <Col lg={{ span: 12, offset: 6 }}>
            <PdTitle size="large" bold>
              Payment Terms
            </PdTitle>
          </Col>
          <Col lg={{ span: 12, offset: 6 }}>
            <div className="content">
              <Paragraph>
                After you`ve placed your order, full payment is required for it
                to be processed. Kindly proceed with the payment via bank
                transfer to:
              </Paragraph>
              <Paragraph>
                BCA: <Text strong>6640666623</Text> <br /> Indrianto
              </Paragraph>
              <Paragraph>
                You will receive an email or phone notification on details of
                payment as well.
              </Paragraph>
              <Paragraph>
                After you’ve made the transfer, please send us a proof of
                payment (picture or screenshot of your transfer receipt) to our
                email <Text strong>eliseeofficial.id@gmail.com</Text> or our
                WhatsApp <Text strong>+62 878-8859-3977</Text>
              </Paragraph>
              <Paragraph>
                Please note that your orders will only be processed after your
                payment is successful.
              </Paragraph>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
