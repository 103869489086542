import React from 'react';
import { Col, Row, Typography } from 'antd';

import { PdTitle } from 'components/pd-title';

import './style.scss';

const { Text, Paragraph } = Typography;

export function OrderFinish() {
  return (
    <div className="pd-order-finish">
      <div className="title">
        <PdTitle align="center" size="large" bold>
          Order Inquiry
        </PdTitle>
      </div>
      <div className="pd-order-finish-content">
        <div className="pd-margin-top-md pd-margin-bottom-md">
          <PdTitle align="center" size="extra-medium" bold>
            Thank you for submitting the form!
          </PdTitle>
        </div>
        <Row gutter={16} className="message">
          <Col lg={{ span: 12, offset: 6 }}>
            <Paragraph>
              Your order inquiry has been successfully submitted. Our team will
              be in touch within 2 business days via e-mail or phone.
              <br />
              <br />
              If you do not receive a confirmation e-mail, or if you have any
              questions, please contact us through e-mail
              <Text strong> eliseeofficial.id@gmail.com </Text>
              or whatsapp <Text strong>+62 878-8859-3977</Text>
            </Paragraph>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default { OrderFinish };
