import React, { useState } from 'react';
import { InputNumber, Row } from 'antd';
import { identity } from 'lodash';

import { PdSliderRange } from 'components/pd-slider-range';

export default function CustomSlider({
  value = [],
  onChange,
  formatter = identity,
  parser = identity,
}) {
  const [sliderRange, setSliderRange] = useState(value);

  function handleChangeRange(type) {
    return function (value) {
      setSliderRange((prev) => {
        const [min, max] = prev;
        return type === 'min' ? [value, max] : [min, value];
      });
    };
  }

  return (
    <>
      <PdSliderRange
        step={sliderRange[0]}
        min={sliderRange[0]}
        max={sliderRange[1]}
        onChange={onChange}
        value={value}
        tipFormatter={formatter}
      />
      <Row type="flex" justify="space-between">
        <InputNumber
          defaultValue={sliderRange[0]}
          min={sliderRange[0]}
          max={sliderRange[1]}
          onChange={handleChangeRange('min')}
          parser={parser}
          formatter={formatter}
          value={value[0]}
        />
        <InputNumber
          defaultValue={sliderRange[0]}
          min={sliderRange[0]}
          max={sliderRange[1]}
          onChange={handleChangeRange('max')}
          parser={parser}
          formatter={formatter}
          value={value[1]}
        />
      </Row>
    </>
  );
}
