/**
 *
 * @param {String} val
 * @example carratParser('5K')
 */

export function carratParser(val) {
  return val.replace('K', '');
}

/**
 *
 * @param {String} val
 * @example currencyParser('Rp.100,000')
 */

export function currencyParser(val) {
  return val.replace(/Rp.\s?|(,*)/g, '');
}
