import RoundDiamond from 'assets/img/diamond-ring/round.png';
import PearDiamond from 'assets/img/diamond-ring/oval.png';

export const COPYWRITE = {
  ERROR_FALLBACK: 'Error, please check your connection',
};

export const DIAMOND_IMAGE_LIST = {
  pear: PearDiamond,
  round: RoundDiamond,
};

export const ORDER_THUMBNAIL_COLUMN = {
  diamonds: 'diamond',
  engagement_rings: 'engagement ring',
  wedding_rings: 'wedding ring',
};

export const TOP_BANNER_DIAMOND = {
  title: 'CERTIFIED DIAMONDS WITH 360° View',
  subtitle_1: `FIND THE PERFECT LOOSE DIAMOND FOR YOUR ENGAGEMENT RING WITH OUR 360° DIAMOND VIEW TECHNOLOGY`,
  subtitle_2: `ALL OF OUR DIAMONDS COME WITH CERTIFICATES FROM INTERNATIONALLY
  RECOGNIZED LABS, THIS WAY YOU ARE CONFIDENT THAT YOU ARE GETTING
  WHAT YOU PAID FOR`,
};

export const BOTTOM_BANNER_DIAMOND = {
  left: {
    title:
      'Get our diamond-buying cheat sheet to help you find your perfect diamond',
    subtitle:
      'The ultimate guide our diamond experts share with their family and friends, and now, with you',
  },
  right: {
    title: 'HAVE A QUESTION ?',
    subtitle: 'Contact our diamond experts to help you find what you need',
  },
};

export const CONTACTS = {
  whatsapp: '+62 878 8859 3977',
  phone: '(021) 458 56 660',
  email: 'eliseeofficial.id@gmail.com',
};
