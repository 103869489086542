import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Col, Row, Table, Typography, message } from 'antd';
import { get, startCase } from 'lodash';

import DiamondApi from 'api/diamond';

import RingPlaceholder from 'assets/img/ring_placeholder.png';
import { Button } from 'components/button';
import { PageSpinner } from 'components/page-spinner';
import { PdBreadcrumbs } from 'components/pd-breadcrumbs';
import { PdGallery } from 'components/pd-gallery';
import { PdModal } from 'components/pd-modal';
import { PdReviewCard } from 'components/pd-review-card';
import { PdTitle } from 'components/pd-title';

import { CartContext } from 'contexts/cart';

import handleError from 'utils/handleError';
import { rupiahFormatter } from 'utils/numberFormatter';

import ImagePlaceholder from 'assets/img/image-not-available.png';

import { columns, reviewData } from './mockData';
import './style.scss';

const { Text } = Typography;

export function DetailDiamondShape({ match }) {
  const { addToCart } = useContext(CartContext);

  const diamondId = get(match, ['params', 'id'], '');

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [pageContent, setPageContent] = useState({});

  const {
    total_sales_price_in_currency: price,
    clarity = '',
    color = '',
    id = '',
    shape = '',
  } = pageContent;
  const breadcrumbData = [
    'Home',
    'Diamond',
    shape,
    `${startCase(shape)}-${id}`,
  ];
  const dataSourceDiamond = [
    {
      key: '1',
      title: 'Shape:',
      data: startCase(shape),
    },
    {
      key: '2',
      title: 'Color:',
      data: color.toUpperCase(),
    },
    {
      key: '3',
      title: 'Average Clarity:',
      data: clarity.toUpperCase(),
    },
  ];

  function getImages({ image_file_url }) {
    const imageUrl = image_file_url !== '' ? image_file_url : ImagePlaceholder;
    return [
      {
        original: imageUrl,
        thumbnail: imageUrl,
      },
    ];
  }

  async function handleAddToCart() {
    try {
      addToCart({
        ...pageContent,
        product_type: 'diamonds',
        product_id: pageContent.id,
      });
      message.success('Product added to cart');
    } catch (error) {
      handleError(error);
    }
  }

  async function fetchDiamondDetail(id) {
    setLoadingFetch(true);
    try {
      const { data = {} } = await DiamondApi.getDiamondDetail(id);

      setPageContent(data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingFetch(false);
    }
  }

  useEffect(() => {
    if (diamondId) fetchDiamondDetail(diamondId);
  }, [diamondId]);

  if (loadingFetch) return <PageSpinner />;

  return (
    <div className="pd-detail-shape">
      <div className="pd-detail-shape-spec">
        <Row className="pd-detail-shape-spec-breadcrumb">
          <Col lg={{ span: 20, offset: 2 }}>
            <PdBreadcrumbs contents={breadcrumbData} />
          </Col>
        </Row>
        <Row gutter={52}>
          <Col lg={{ span: 10, offset: 2 }}>
            <PdGallery images={getImages(pageContent)} />
          </Col>
          <Col lg={{ span: 10 }} className="pd-detail-shape-spec-description">
            <div className="title">
              <PdTitle font="montserrat" color="black" bold size="medium">
                {`${startCase(shape)}-${id}`}
              </PdTitle>
            </div>
            <div>
              <PdTitle font="montserrat" color="black" size="small">
                {`${color.toUpperCase()} Color  |  ${clarity.toUpperCase()} Clarity`}
              </PdTitle>
            </div>
            <div className="price">
              <PdTitle font="montserrat" color="black" bold size="extra-medium">
                {rupiahFormatter(price)}
              </PdTitle>
            </div>
            <div>
              <Button
                size="medium"
                className="add-cart-button"
                onClick={handleAddToCart}>
                Add to Cart
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="pd-detail-shape-desc">
        <Row className="pd-detail-shape-desc-wrapper">
          <Col lg={{ span: 20, offset: 2 }}>
            <div className="title">
              <PdTitle font="montserrat" color="black" bold size="medium">
                {`${startCase(shape)}-${id}`}
              </PdTitle>
            </div>
          </Col>
        </Row>
        <Row gutter={24} className="pd-detail-shape-desc-wrapper">
          <Col lg={{ span: 10, offset: 2 }}>
            <div className="subtitle">
              <PdTitle
                level={2}
                font="montserrat"
                bold
                size="small"
                color="black">
                Diamond
              </PdTitle>
            </div>
            <Table
              columns={columns}
              dataSource={dataSourceDiamond}
              pagination={false}
              showHeader={false}
              className="table"
            />
          </Col>
        </Row>
      </div>

      <Row>
        <Col lg={20} offset={2}>
          <div className="pd-detail-shape-review">
            <Row className="pd-detail-shape-desc-wrapper">
              <Col lg={20}>
                <div className="title">
                  <PdTitle font="montserrat" color="black" bold size="medium">
                    Review
                  </PdTitle>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={20}>
                {reviewData.map((data, idx) => {
                  const {
                    prof_pic = '',
                    job = '',
                    name = '',
                    review = '',
                  } = data ?? {};
                  return (
                    <PdReviewCard
                      avatarSize={80}
                      key={idx}
                      prof_pic={prof_pic}
                      job={job}
                      name={name}
                      review={review}
                    />
                  );
                })}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <PdModal
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        visible={isModalVisible}
        width={1000}>
        <Row type="flex" justify="center">
          <PdTitle
            level={2}
            size="extra-medium"
            className="pd-margin-bottom-md">
            You have choosen a ring
          </PdTitle>
          <Row type="flex" justify="center" align="middle">
            <Col lg={3}>
              <img
                src={RingPlaceholder}
                alt="ring modal"
                style={{ maxWidth: '100%' }}
              />
            </Col>
            <Col lg={5} offset={1}>
              <PdTitle
                bold
                className="pd-margin-bottom-xs"
                color="black"
                level={3}>
                Ring Series
              </PdTitle>
              <PdTitle
                level={4}
                bold
                color="black"
                className="pd-margin-top-xs pd-margin-bottom-xs">
                Rp 0
              </PdTitle>
              <Link to="/">
                <Text className="modal-link" type="link">
                  Change a ring
                </Text>
              </Link>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col lg={24}>
              <Button block className="pd-margin-bottom-sm pd-margin-top-md">
                CONTINUE TO DIAMOND SELECTION
              </Button>
            </Col>
            <Col lg={24}>
              <Button block>CHECKOUT</Button>
            </Col>
          </Row>
        </Row>
      </PdModal>
    </div>
  );
}

export default { DetailDiamondShape };
