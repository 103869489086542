import React from 'react';
import { Tag } from 'antd';

import './style.scss';

const { CheckableTag } = Tag;

export function PdCheckableTag({
  checked = 0,
  children,
  className = '',
  keys = '',
  onChange = () => {},
  style = {},
}) {
  return (
    <CheckableTag
      checked={checked}
      className={`pd-checkabletags ${className}`}
      key={keys}
      onChange={onChange}
      style={style}>
      {children}
    </CheckableTag>
  );
}

export default { PdCheckableTag };
