import React from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import { PdTitle } from 'components/pd-title';

import BottomBanner from './components/bottom-banner';
import TopBanner from './components/top-banner';
import { diamondShapes } from './mockData';
import './style.scss';

export function Diamonds() {
  return (
    <div className="pd-diamonds">
      <TopBanner />

      <div className="pd-padding-top-xl pd-padding-bottom-xl wrapper">
        <Row
          align="middle"
          className="pd-margin-bottom-lg"
          justify="center"
          type="flex">
          <PdTitle align="center" size="extra-large" color="light">
            Explore Diamond Shapes
          </PdTitle>
        </Row>

        <Row type="flex" justify="center">
          <Col md={16}>
            <Slider
              slidesToShow={4}
              slidesToScroll={2}
              speed={1000}
              className="diamond-slider">
              {diamondShapes.map(({ image, shape } = {}) => (
                <div key={shape}>
                  <Link
                    to={{ pathname: '/diamonds-shapes', state: { shape } }}
                    className="shape-container">
                    <img
                      src={image}
                      width={100}
                      height={100}
                      alt={`type-${shape}`}
                    />
                  </Link>
                  <div>
                    <PdTitle
                      color="black"
                      size="medium"
                      bold
                      align="center"
                      className="pd-margin-top-md">
                      {shape}
                    </PdTitle>
                  </div>
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
      </div>
      <BottomBanner />
    </div>
  );
}

export default { Diamonds };
