import api from './index';
import { omitBy } from 'lodash';
import { isNil } from 'utils';

export default {
  getDiamondResult(payload, { page = 1, sortBy = '' }) {
    const params = { limit: 12, page, sortBy };
    return api.post('/diamonds', payload, { params: omitBy(params, isNil) });
  },
  getDiamondDetail(id) {
    return api.get(`/diamonds/${id}`);
  },
  getDiamondPriceRange() {
    return api.get('/diamonds/range_price');
  },
};
