import React from 'react';
import { Avatar, Col, Row, Typography } from 'antd';

import './style.scss';

const { Text } = Typography;

export function PdReviewCard({
  prof_pic = '',
  name = '',
  job = '',
  review = '',
  avatarSize = 128,
  responsiveAvatar = {},
}) {
  return (
    <div className="pd-review-card">
      <Row>
        <Col lg={4}>
          {!prof_pic ? (
            <Avatar
              size={
                Object.entries(responsiveAvatar).length
                  ? responsiveAvatar
                  : avatarSize
              }
              icon="user"
            />
          ) : (
            <Avatar
              size={
                Object.entries(responsiveAvatar).length
                  ? responsiveAvatar
                  : avatarSize
              }
              src={prof_pic}
            />
          )}
        </Col>
        <Col lg={20}>
          <Row>
            <Col span={24} className="pd-review-card-comment">
              <Text>{review}</Text>
            </Col>
            <Col>
              <Text>
                <b>{name} </b>- {job}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default { PdReviewCard };
