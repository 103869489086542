import React from 'react';

import './style.scss';

export function Section(props) {
  const {
    title = 'PandaTech Template',
    subtitle = 'Develop Faster',
    className = '',
  } = props;

  return (
    <div className={`pd-template ${className}`}>
      <h1>{title}</h1>
      <h3>{subtitle}</h3>
    </div>
  );
}

export default { Section };
