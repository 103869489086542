import React, { useRef } from 'react';
import { Avatar, Carousel, Col, Row } from 'antd';
import {
  EnvironmentFilled,
  LeftCircleFilled,
  MailFilled,
  PhoneFilled,
  RightCircleFilled,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { Button } from 'components/button';
import { PdTitle } from 'components/pd-title';
import { Container } from 'components/container';

import Banner1 from 'assets/img/banner-1.png';
import Banner2 from 'assets/img/banner-2.png';
import Diamond from 'assets/img/diamond.png';
import EngagementRing from 'assets/img/engagement-ring.png';
import WeddingRing from 'assets/img/wedding-ring.png';

import { PROPOSAL_LIST } from './data';
import './style.scss';

export function Home() {
  const carouselRef = useRef();

  return (
    <div className="pd-home">
      <div className="carousel-wrapper">
        <RightCircleFilled
          style={{ right: 32 }}
          onClick={() => carouselRef.current.next()}
        />
        <LeftCircleFilled
          style={{ left: 32 }}
          onClick={() => carouselRef.current.prev()}
        />
        <Carousel ref={carouselRef} dots={false}>
          <Row
            align="middle"
            justify="center"
            gutter={[0, 18]}
            className="intro">
            <Col xs={20} lg={12} className="intro-wrapper">
              <div className="title">
                <PdTitle size="large">DESIGN YOUR OWN</PdTitle>
              </div>
              <div className="title-bold">
                <PdTitle size="large" bold>
                  ENGAGEMENT RING
                </PdTitle>
              </div>
              <hr className="divider" />
              <PdTitle font="montserrat" size="small" className="subtitle">
                YOUR DESIGN, YOUR STORY
              </PdTitle>
              <Row align="middle" justify="center" gutter={16}>
                <Col xs={12} sm={10}>
                  <Link to="/product/engagement-rings">
                    <Button variant="dark">Start With A Settings</Button>
                  </Link>
                </Col>
                <Col xs={0} sm={2} className="button-divider">
                  <p style={{ margin: 0 }}>or</p>
                </Col>
                <Col xs={12} sm={10}>
                  <Link to="/diamonds">
                    <Button variant="dark">Start With A Diamond</Button>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={12} className="intro-image">
              <img alt="Design your own ring." src={Banner1} />
            </Col>
          </Row>

          <Row
            align="middle"
            justify="center"
            gutter={[0, 18]}
            className="intro">
            <Col xs={24} lg={12} className="intro-image">
              <img alt="Love Bands Elisee." src={Banner2} />
            </Col>
            <Col xs={20} lg={12} className="intro-wrapper">
              <div className="title-bold">
                <PdTitle size="double-extra-large" bold align="center">
                  LOVE BANDS
                </PdTitle>
              </div>
              <hr className="divider" />
              <PdTitle font="montserrat" size="small" className="subtitle">
                FOREVER YOURS
              </PdTitle>
              <div className="text">
                Discover the perfect wedding rings for you and your loved ones
              </div>
              <Row
                align="middle"
                justify="center"
                gutter={16}
                style={{ width: '100%' }}>
                <Col xs={10} sm={8}>
                  <Link to="/product/wedding-rings?gender=female">
                    <Button variant="dark" padding="large" block>
                      For her
                    </Button>
                  </Link>
                </Col>
                <Col xs={0} sm={2} className="button-divider">
                  <p style={{ margin: 0 }}>or</p>
                </Col>
                <Col xs={10} sm={8}>
                  <Link to="/product/wedding-rings?gender=male">
                    <Button variant="dark" padding="large" block>
                      For him
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="explore">
            <Row align="middle" justify="space-around" gutter={[0, 52]}>
              <Col lg={24}>
                <PdTitle size="large" align="center" color="secondary">
                  EXPLORE ÉLISÉE
                </PdTitle>
              </Col>
              <Col xs={24} lg={7}>
                <Link
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  to="/product/engagement-rings">
                  <div>
                    <PdTitle
                      className="subtitle"
                      size="medium"
                      align="center"
                      color="inherit">
                      ENGAGEMENT RINGS
                    </PdTitle>
                  </div>
                  <img alt="" src={EngagementRing} width={156} />
                </Link>
              </Col>
              <Col xs={24} lg={7}>
                <Link
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  to="/product/wedding-rings">
                  <div>
                    <PdTitle
                      className="subtitle"
                      size="medium"
                      align="center"
                      color="inherit">
                      WEDDING RINGS
                    </PdTitle>
                  </div>
                  <img alt="" src={WeddingRing} width={156} />
                </Link>
              </Col>
              <Col xs={24} lg={7}>
                <Link
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  to="/diamonds">
                  <div>
                    <PdTitle
                      className="subtitle"
                      size="medium"
                      align="center"
                      color="inherit">
                      DIAMONDS
                    </PdTitle>
                  </div>
                  <img alt="" src={Diamond} width={156} />
                </Link>
              </Col>
            </Row>
          </div>
        </Carousel>
      </div>

      <div className="about">
        <Row type="flex" justify="space-around">
          <Col xs={20} lg={{ span: 12 }}>
            <div className="about-title">
              <PdTitle size="Large" align="center" color="secondary" italic>
                #SheSaidYesWithÉLISÉE
              </PdTitle>
            </div>
            <div className="about-subtitle">
              <PdTitle size="small" font="montserrat" align="center">
                {
                  "VIEW A COLLECTION OF OUR CUSTOMER'S PRECIOUS PROPOSAL MOMENT!"
                }
              </PdTitle>
            </div>
          </Col>
        </Row>
        <Container>
          <Row gutter={[0, 10]}>
            {PROPOSAL_LIST.map(({ image, text }, index) => {
              let xs = 0;
              if (index < 4) {
                xs = 12;
              }
              return (
                <Col key={text} lg={6} xs={xs} className="pd-align-center">
                  <Avatar
                    className="pd-margin-bottom-sm"
                    size={{
                      xs: 130,
                      sm: 260,
                      md: 260,
                      lg: 260,
                      xl: 260,
                      xxl: 260,
                    }}
                    src={image}
                  />
                  <PdTitle align="center" className="pd-margin-bottom-md">
                    {text}
                  </PdTitle>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>

      <div className="contact" id="contact">
        <Row type="flex" justify="space-around">
          <Col lg={{ span: 12 }}>
            <div className="contact-title">
              <PdTitle size="large" align="center" color="secondary">
                Contact Us
              </PdTitle>
            </div>
          </Col>
        </Row>
        <Row justify="center" gutter={[32, 32]}>
          <Col xs={20} lg={9}>
            <iframe
              frameBorder="0"
              height="300"
              id="gmap_canvas"
              marginHeight="0"
              marginWidth="0"
              scrolling="no"
              src="https://maps.google.com/maps?q=mall%20artha%20gading&t=&z=13&ie=UTF8&iwloc=&output=embed"
              title="gmaps"
              style={{ borderRadius: 18 }}
              width="100%"></iframe>
          </Col>
          <Col xs={20} lg={9}>
            <div className="contact-wrapper">
              <div className="contact-wrapper-title">
                <PdTitle level={2} font="montserrat" size="medium" bold>
                  Let’s create your dream jewelry
                </PdTitle>
              </div>
              <div className="pd-margin-bottom-md">
                Send us a message and we will be in touch within one business
                day
              </div>
              <div className="pd-margin-bottom-sm">
                <EnvironmentFilled className="pd-margin-right-sm" />
                Mall Artha Gading Ground Floor B5
              </div>
              <div className="pd-margin-bottom-sm">
                <PhoneFilled className="pd-margin-right-sm" />
                <a
                  href="https://api.whatsapp.com/send?phone=+6287888593977"
                  rel="noopener noreferrer"
                  target="_blank">
                  +62 878-8859-3977
                </a>
              </div>
              <div className="pd-margin-bottom-sm">
                <MailFilled className="pd-margin-right-sm" />
                <a
                  href="mailto:eliseeofficial.id@gmail.com"
                  rel="noopener noreferrer"
                  target="_blank">
                  eliseeofficial.id@gmail.com
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default { Home };
