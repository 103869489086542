import OvalDiamonds from 'assets/img/diamond-ring/oval.png';

export const diamondData = [
  {
    image: OvalDiamonds,
    name: 'El Elyssium',
    price: 5000000,
  },
  {
    image: OvalDiamonds,
    name: '金指輪',
    price: 25000000,
  },
  {
    image: OvalDiamonds,
    name: 'The Big Value',
    price: 150000000,
  },
  {
    image: OvalDiamonds,
    name: 'Virtue Magnifico',
    price: 25000000,
  },
  {
    image: OvalDiamonds,
    name: 'Cincin Persatuan',
    price: 15000000,
  },
  {
    image: OvalDiamonds,
    name: 'Our Story',
    price: 21000000,
  },
  {
    image: OvalDiamonds,
    name: '結婚式',
    price: 20000000,
  },
  {
    image: OvalDiamonds,
    name: 'El Bellisimo',
    price: 21000000,
  },
  {
    image: OvalDiamonds,
    name: 'Al-Fadheel',
    price: 400000000,
  },
  {
    image: OvalDiamonds,
    name: 'Arcane Picaro',
    price: 24000000,
  },
  {
    image: OvalDiamonds,
    name: 'Koh-i Noor',
    price: 26000000,
  },
  {
    image: OvalDiamonds,
    name: 'Goldy Boldy',
    price: 4000000,
  },
];

export const diamondCut = {
  1: 'Excellent',
  2: 'Very Good',
  3: 'Good',
  4: 'Fair',
  5: 'Poor',
};

export const diamondColor = {
  1: 'D',
  2: 'E',
  3: 'F',
  4: 'G',
  5: 'H',
  6: 'I',
  7: 'J',
  8: 'K',
  9: 'L',
  10: 'M',
};

export const diamondClarity = {
  1: 'IF',
  2: 'VVS1',
  3: 'VVS2',
  4: 'VS1',
  5: 'VS2',
  6: 'SI1',
  7: 'SI2',
  8: 'SI3',
  9: 'I1',
  10: 'I2',
  11: 'I3',
};

export const diamondTypes = [
  'all',
  'Round',
  'Pear',
  'Princess',
  'Marquise',
  'Oval',
  'Radiant',
  'Emerald',
  'Heart',
  'Cushion',
  'Asscher',
];
