import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';

import TransactionAPI from 'api/transaction';

import handleError from 'utils/handleError';

export const CartContext = createContext();
function CartContextProvider({ children }) {
  const [cart, setCart] = useState([]);

  function addToCart(payload) {
    const storageCart = localStorage.getItem('cart');
    if (storageCart?.includes((product) => product.id === payload.id)) {
      return;
    }

    const parsedStorageCart = storageCart ? JSON.parse(storageCart) : [];
    const newCart = [...parsedStorageCart, payload];
    saveCart(newCart);
  }

  function removeFromCart({ id, type }) {
    const { cart: storageCart = [] } = localStorage;
    const newCart = JSON.parse(storageCart).filter(
      ({ product_id, product_type }) =>
        +product_id !== +id || product_type !== type
    );
    saveCart(newCart);
  }

  function clearCart() {
    localStorage.removeItem('cart');
    setCart([]);
  }

  function saveCart(newCart) {
    if (newCart.length === 0) {
      clearCart();
      return;
    }

    localStorage.setItem('cart', JSON.stringify(newCart));
    fetchCart(newCart);
  }

  function getCartIds(cart) {
    const container = { engagement_rings: [], wedding_rings: [], diamonds: [] };
    cart.forEach(({ product_id, product_type }) => {
      container[product_type] = [...container[product_type], product_id];
    });
    return container;
  }

  function parseCart(cart) {
    return Object.keys(cart)
      .map((key) => {
        return cart[key].map((product) => ({
          ...product,
          type: key,
        }));
      })
      .flat();
  }

  async function fetchCart(payload) {
    try {
      const { data } = await TransactionAPI.getProductInCart(
        getCartIds(payload)
      );

      // modify diamonds here
      const { diamonds = [] } = data;
      const modifiedDiamonds = diamonds.map((diamond = {}) => {
        const { id, shape = '' } = diamond;
        return {
          ...diamond,
          title: `${startCase(shape)}-${id}`,
          price: diamond.total_sales_price_in_currency,
        };
      });
      setCart(parseCart({ ...data, diamonds: modifiedDiamonds }));
    } catch (error) {
      handleError(error);
    }
  }

  useEffect(() => {
    if (localStorage?.cart) {
      const storageCart = JSON.parse(localStorage.cart);
      fetchCart(storageCart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CartContext.Provider
      value={{
        addToCart,
        cart,
        removeFromCart,
        clearCart,
      }}>
      {children}
    </CartContext.Provider>
  );
}

CartContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CartContextProvider;
