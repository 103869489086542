import React, { useState } from 'react';
import { Col, Row, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { Button } from 'components/button';
import { PdBreadcrumbs } from 'components/pd-breadcrumbs';
import { PdGallery } from 'components/pd-gallery';
import { PdReviewCard } from 'components/pd-review-card';
import { PdTitle } from 'components/pd-title';
import { PdModal } from 'components/pd-modal';
import { PdProductCard } from 'components/pd-product-card';

import RingPlaceholder from 'assets/img/ring_placeholder.png';

import { rupiahFormatter } from 'utils/numberFormatter';

import {
  breadcrumbData,
  columns,
  dataSourceDiamond,
  imageData,
  matchingBandData,
  reviewData,
  detailDiamond,
} from './mockData';
import './style.scss';

const { Text } = Typography;

export function DetailDiamondColor() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <div className="pd-detail-color">
      <div className="pd-detail-color-spec">
        <Row className="pd-detail-color-spec-breadcrumb">
          <Col lg={{ span: 20, offset: 2 }}>
            <PdBreadcrumbs contents={breadcrumbData} />
          </Col>
        </Row>
        <Row gutter={52}>
          <Col lg={{ span: 10, offset: 2 }}>
            <PdGallery images={imageData} />
          </Col>
          <Col lg={{ span: 10 }} className="pd-detail-color-spec-description">
            <div className="title">
              <PdTitle font="montserrat" color="black" bold size="medium">
                Lorem Ipsum
              </PdTitle>
            </div>
            <div>
              <PdTitle font="montserrat" color="black" size="small">
                {`${detailDiamond.color} Color  |  ${detailDiamond.clarity} Clarity  |  ${detailDiamond.cut} Cut`}
              </PdTitle>
            </div>
            <div>
              <PdTitle
                font="garamond"
                color="inherit"
                className="subtitle"
                size="small">
                {detailDiamond.sku}
              </PdTitle>
            </div>
            <div className="price">
              <PdTitle font="montserrat" color="black" bold size="extra-medium">
                {rupiahFormatter(100000000)}
              </PdTitle>
            </div>
            <PdTitle level={1} className="price"></PdTitle>
            <Text>
              Exercitation exercitation nostrud ut et labore qui qui
              adipisicing. Quis consectetur magna sint laborum consequat ullamco
              qui non. Ad exercitation cupidatat dolor eiusmod. Sit
              reprehenderit laboris labore nulla id est anim pariatur non.
            </Text>
            <div>
              <Button size="medium" className="add-cart-button">
                Add to Cart
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="pd-detail-color-desc">
        <Row className="pd-detail-color-desc-wrapper">
          <Col lg={{ span: 20, offset: 2 }}>
            <div className="title">
              <PdTitle font="montserrat" color="black" bold size="medium">
                Lorem Ipsum
              </PdTitle>
            </div>
          </Col>
        </Row>
        <Row className="pd-detail-color-desc-wrapper">
          <Col lg={{ span: 20, offset: 2 }} className="content-desc">
            <Text>
              Est magna enim Lorem dolor aute mollit esse ea incididunt ut
              consectetur. Deserunt esse ullamco exercitation laborum ea nisi
              proident quis. Cupidatat ex incididunt fugiat deserunt. Veniam est
              excepteur in id ea commodo esse proident ea fugiat. Occaecat
              laboris elit velit eu occaecat ad magna dolore consectetur
              exercitation. Ipsum nisi voluptate consequat ipsum velit ex.
              Nostrud dolore consequat sit Lorem eu. Velit consequat do commodo
              non culpa est et laboris nulla reprehenderit velit culpa
              incididunt. Eiusmod dolor id non elit.
            </Text>
          </Col>
        </Row>
        <Row gutter={24} className="pd-detail-color-desc-wrapper">
          <Col lg={{ span: 10, offset: 2 }}>
            <div className="subtitle">
              <PdTitle
                level={2}
                font="montserrat"
                bold
                size="small"
                color="black">
                Diamond
              </PdTitle>
            </div>
            <Table
              columns={columns}
              dataSource={dataSourceDiamond}
              pagination={false}
              showHeader={false}
              className="table"
            />
          </Col>
        </Row>
      </div>

      {/* matching bands */}
      <div className="pd-detail-color-review">
        <Row className="pd-detail-color-desc-wrapper">
          <Col lg={{ span: 20, offset: 2 }}>
            <div className="title">
              <PdTitle font="montserrat" color="black" bold size="medium">
                Similar Items
              </PdTitle>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {matchingBandData.map((ring, idx) => {
            const { name = '', price = 0 } = ring ?? {};
            return (
              <Col
                lg={5}
                offset={!idx ? 2 : 0}
                key={name}
                className="pd-product-card">
                <PdProductCard hoverable>
                  <img
                    src={RingPlaceholder}
                    alt={name}
                    className="pd-matching-card-image"
                  />
                  <PdTitle
                    align="center"
                    bold
                    color="black"
                    level={4}
                    className="pd-matching-card-title">
                    {name}
                  </PdTitle>
                  <Text>{rupiahFormatter(price)}</Text>
                </PdProductCard>
              </Col>
            );
          })}
        </Row>
      </div>

      {/* banner section */}

      <Row>
        <Col lg={20} offset={2}>
          <div className="pd-detail-color-review">
            <Row className="pd-detail-color-desc-wrapper">
              <Col lg={20}>
                <div className="title">
                  <PdTitle font="montserrat" color="black" bold size="medium">
                    Review
                  </PdTitle>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={20}>
                {reviewData.map((data, idx) => {
                  const {
                    prof_pic = '',
                    job = '',
                    name = '',
                    review = '',
                  } = data ?? {};
                  return (
                    <PdReviewCard
                      avatarSize={80}
                      key={idx}
                      prof_pic={prof_pic}
                      job={job}
                      name={name}
                      review={review}
                    />
                  );
                })}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <PdModal
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        visible={isModalVisible}
        width={1000}>
        <Row type="flex" justify="center">
          <PdTitle
            level={2}
            size="extra-medium"
            className="pd-margin-bottom-md">
            You have choosen a ring
          </PdTitle>
          <Row type="flex" justify="center" align="middle">
            <Col lg={3}>
              <img
                src={RingPlaceholder}
                alt="ring modal"
                style={{ maxWidth: '100%' }}
              />
            </Col>
            <Col lg={5} offset={1}>
              <PdTitle
                bold
                className="pd-margin-bottom-xs"
                color="black"
                level={3}>
                Ring Series
              </PdTitle>
              <PdTitle
                level={4}
                bold
                color="black"
                className="pd-margin-top-xs pd-margin-bottom-xs">
                Rp 0
              </PdTitle>
              <Link to="/">
                <Text className="modal-link" type="link">
                  Change a ring
                </Text>
              </Link>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col lg={24}>
              <Button block className="pd-margin-bottom-sm pd-margin-top-md">
                CONTINUE TO DIAMOND SELECTION
              </Button>
            </Col>
            <Col lg={24}>
              <Button block>CHECKOUT</Button>
            </Col>
          </Row>
        </Row>
      </PdModal>
    </div>
  );
}

export default { DetailDiamondColor };
