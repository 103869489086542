import React from 'react';
import { Slider } from 'antd';
import { identity } from 'lodash';

import './style.scss';

export function PdSliderRange({
  className = '',
  tipFormatter = identity,
  ...rest
}) {
  return (
    <Slider
      className={`pd-slider-range ${className}`}
      tipFormatter={tipFormatter}
      range
      {...rest}
    />
  );
}

export default { PdSliderRange };
