import api from './index';
import { omitBy } from 'lodash';
import { isNil } from 'utils';

export default {
  getRingList({
    productType = '',
    payload,
    page = 1,
    limit = 12,
    sortBy = '',
  }) {
    const params = {
      limit,
      page,
      sortBy,
    };
    return api.post(`/${productType}`, payload, {
      params: omitBy(params, isNil),
    });
  },
  getDetailRing({ productType = '', id = '' }) {
    return api.get(`/${productType}/${id}`);
  },
  getRingsPriceRange({ productType = '' }) {
    return api.get(`/${productType}/range_price`);
  },
};
