// shape
import AsscherDiamonds from 'assets/img/diamond-ring/asscher.png';
import CushionDiamonds from 'assets/img/diamond-ring/cushion.png';
import EmeraldDiamonds from 'assets/img/diamond-ring/emerald.png';
import HeartDiamonds from 'assets/img/diamond-ring/heart.png';
import MarquiseDiamonds from 'assets/img/diamond-ring/marquise.png';
import OvalDiamonds from 'assets/img/diamond-ring/oval.png';
import PearDiamonds from 'assets/img/diamond-ring/pear.png';
import PrincessDiamonds from 'assets/img/diamond-ring/princess.png';
import RadiantDiamonds from 'assets/img/diamond-ring/radiant.png';
import RoundDiamonds from 'assets/img/diamond-ring/round.png';

// color
import YellowDiamonds from 'assets/img/diamond-ring/yellow.png';
import RedDiamonds from 'assets/img/diamond-ring/red.png';
import PurpleDiamonds from 'assets/img/diamond-ring/purple.png';
import OrangeDiamonds from 'assets/img/diamond-ring/orange.png';

export const diamondShapes = [
  {
    image: AsscherDiamonds,
    shape: 'Asscher',
  },
  {
    image: CushionDiamonds,
    shape: 'Cushion',
  },
  {
    image: EmeraldDiamonds,
    shape: 'Emerald',
  },
  {
    image: HeartDiamonds,
    shape: 'Heart',
  },
  {
    image: MarquiseDiamonds,
    shape: 'Marquise',
  },
  {
    image: OvalDiamonds,
    shape: 'Oval',
  },
  {
    image: PearDiamonds,
    shape: 'Pear',
  },
  {
    image: PrincessDiamonds,
    shape: 'Princess',
  },
  {
    image: RadiantDiamonds,
    shape: 'Radiant',
  },
  {
    image: RoundDiamonds,
    shape: 'Round',
  },
];

export const diamondColors = [
  {
    image: YellowDiamonds,
    color: 'Yellow',
  },
  {
    image: RedDiamonds,
    color: 'Red',
  },
  {
    image: PurpleDiamonds,
    color: 'Purple',
  },
  {
    image: OrangeDiamonds,
    color: 'Orange',
  },
];
