import React from 'react';
import { Radio } from 'antd';
import { startCase } from 'lodash';

const { Group, Button } = Radio;

import './style.scss';

export function PdRadioButton({ className = '', options = [], ...props }) {
  return (
    <Group {...props} className={`pd-radio-button ${className}`}>
      {options.map((option) => {
        return (
          <Button key={option} value={option}>
            {startCase(option)}
          </Button>
        );
      })}
    </Group>
  );
}
