import React, { useState } from 'react';
import {
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Tabs,
  Upload,
  message,
} from 'antd';
import {
  EnvironmentFilled,
  MailOutlined,
  PhoneOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons';

import ImageAPI from 'api/image';
import TransactionAPI from 'api/transaction';

import { Button } from 'components/button';
import { PdCheckableTag } from 'components/pd-checkabletag';
import { PdSliderRange } from 'components/pd-slider-range';
import { PdTitle } from 'components/pd-title';

import handleError from 'utils/handleError';
import { carratFormatter, rupiahFormatter } from 'utils/numberFormatter';
import { carratParser, currencyParser } from 'utils/numberParser';

import './style.scss';

const { TabPane } = Tabs;
const { TextArea } = Input;

const FORM_FIELD = {
  DESCRIPTION: 'description',
  EMAIL: 'email',
  NAME: 'name',
  PHONE: 'phone',
  IMAGE: 'image',
};

const defaultStoneCarrat = [0.25, 5];
const defaultPriceRange = [1000000, 50000000];

export function ContactForm() {
  const [ring, setRing] = useState('engagement');
  const [setting, setsetting] = useState('');
  const [stone, setStone] = useState('');
  const [stoneCarrat, setStoneCarrat] = useState(defaultStoneCarrat);
  const [priceRange, setPriceRange] = useState(defaultPriceRange);
  const [uploadURL, setUploadURL] = useState({});
  const [imageURL, setImageURL] = useState('');
  const [form] = Form.useForm();

  const settingTypes = {
    solitaire: 'Solitaire',
    halo: 'Halo',
    pave: 'Pavé',
    '3_stone': '3 Stone',
    custom: 'Custom',
  };

  const stoneTypes = {
    diamond: 'Diamond',
    no_stone: 'No Stone',
  };

  function handleChangePriceRange(value) {
    setPriceRange(value);
  }

  function handleChangePriceRangeMin(value) {
    const newVal = [...priceRange];
    newVal[0] = value;
    setPriceRange(newVal);
  }

  function handleChangePriceRangeMax(value) {
    const newVal = [...priceRange];
    newVal[1] = value;
    setPriceRange(newVal);
  }

  function handleChangeStoneCarrat(value) {
    setStoneCarrat(value);
  }

  function handleChangeStoneCarratMin(value) {
    const newVal = [...stoneCarrat];
    newVal[0] = value;
    setStoneCarrat(newVal);
  }

  function handleChangeStoneCarratMax(value) {
    const newVal = [...stoneCarrat];
    newVal[1] = value;
    setStoneCarrat(newVal);
  }

  function handleChangeRing(key) {
    setRing(key);
  }

  async function beforeUpload(file) {
    try {
      const ext = file.type.split('/')[1];
      const { data } = await ImageAPI.getLinkUpload({
        productType: ring + '_ring',
        ext: ext,
      });
      setUploadURL(data);
    } catch (err) {
      handleError(err);
    }
  }

  async function awsUpload(file) {
    try {
      await ImageAPI.upload({
        url: uploadURL.uploadUrl,
        payload: file.file,
      });
      setImageURL(uploadURL.objectUrl);
      message.success('Berhasil mengupload');
    } catch (err) {
      handleError(err);
    }
  }

  async function handleSubmit(values) {
    const {
      description = '',
      email = '',
      name = '',
      phone = '',
    } = values || {};
    try {
      const ringType = ring + '_ring';
      // eslint-disable-next-line no-unused-vars
      const payload = {
        notes: description,
        email,
        img_url: imageURL,
        name,
        phone_number: phone,
        ring: ringType,
        setting,
        stone,
        min_carat: stoneCarrat[0],
        max_carat: stoneCarrat[1],
        min_price: priceRange[0],
        max_price: priceRange[1],
      };

      const { data } = await TransactionAPI.customOrder(payload);
      message.success(data.message);
    } catch (e) {
      message.error(`custom order failed.`);
    }
  }

  return (
    <div className="pd-contact">
      <div className="pd-contact-location" id="contact">
        <Row type="flex" justify="space-around">
          <Col lg={{ span: 12 }}>
            <PdTitle size="large" bold color="black" align="center">
              Contact Us
            </PdTitle>
          </Col>
        </Row>
        <Row type="flex" justify="space-around">
          <Col lg={{ span: 8, offset: 3 }}>
            <iframe
              frameBorder="0"
              height="300"
              id="gmap_canvas"
              marginHeight="0"
              marginWidth="0"
              scrolling="no"
              src="https://maps.google.com/maps?q=mall%20artha%20gading&t=&z=13&ie=UTF8&iwloc=&output=embed"
              title="gmaps"
              style={{ borderRadius: 18 }}
              width="100%"></iframe>
          </Col>
          <Col lg={{ span: 9, offset: 2 }}>
            <div className="pd-contact-location-wrapper">
              <PdTitle
                level={2}
                font="montserrat"
                size="medium"
                color="black"
                bold>
                Lorem ipsum dolor sit amet
              </PdTitle>
              <div className="pd-contact-location-wrapper-subtitle">
                Tempor cupidatat fugiat aliquip sit velit reprehenderit mollit
                qui Lorem irure elit veniam sunt aliqua.
              </div>
              <div>
                <EnvironmentFilled /> Mall Artha Gading
              </div>
              <div>
                <PhoneOutlined type="phone" />
                <a
                  href="https://api.whatsapp.com/send?phone=+6287888593977"
                  rel="noopener noreferrer"
                  target="_blank">
                  +6287888593977
                </a>
              </div>
              <div>
                <MailOutlined type="mail" />
                TBD
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="pd-contact-form">
        <Row type="flex" justify="space-around">
          <Col lg={{ span: 12 }}>
            <PdTitle size="large" bold color="black" align="center">
              Send Us Your Design
            </PdTitle>
          </Col>
        </Row>
        <div className="pd-contact-form-wrapper">
          <Form form={form} onFinish={handleSubmit}>
            <div className="pd-contact-form-wrapper-input">
              <Row type="flex" gutter={[12, 12]}>
                <Col lg={{ offset: 6, span: 6 }}>
                  <PdTitle
                    level={3}
                    font="montserrat"
                    bold
                    color="black"
                    size="medium">
                    Ring
                  </PdTitle>
                  <Tabs
                    onChange={handleChangeRing}
                    className="pd-contact-form-wrapper-tabs">
                    <TabPane tab="Engagement" key="engagement" />
                    <TabPane tab="Wedding" key="wedding" />
                  </Tabs>
                </Col>
                <Col flex="auto" lg={{ span: 6 }}>
                  <PdTitle
                    level={3}
                    font="montserrat"
                    bold
                    color="black"
                    size="medium">
                    Stone
                  </PdTitle>
                  <div className="pd-contact-form-wrapper-tags">
                    {Object.keys(stoneTypes).map((keys) => (
                      <PdCheckableTag
                        key={keys}
                        checked={stone.indexOf(keys) > -1}
                        onChange={() => setStone(keys)}>
                        {stoneTypes[keys]}
                      </PdCheckableTag>
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="pd-contact-form-wrapper-input">
              <Row type="flex">
                <Col lg={{ offset: 6, span: 12 }}>
                  <PdTitle
                    level={3}
                    font="montserrat"
                    bold
                    color="black"
                    size="medium">
                    Setting
                  </PdTitle>
                  <div className="pd-contact-form-wrapper-tags">
                    {Object.keys(settingTypes).map((keys) => (
                      <PdCheckableTag
                        key={keys}
                        checked={setting.indexOf(keys) > -1}
                        onChange={() => setsetting(keys)}>
                        {settingTypes[keys]}
                      </PdCheckableTag>
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="pd-contact-form-wrapper-slider">
              <Row type="flex">
                <Col flex="auto" lg={{ offset: 6, span: 12 }}>
                  <PdTitle
                    level={3}
                    font="montserrat"
                    bold
                    color="black"
                    size="medium">
                    Stone Carrat
                  </PdTitle>
                  <PdSliderRange
                    className="pd-contact-form-slider"
                    defaultValue={defaultStoneCarrat}
                    max={defaultStoneCarrat[1]}
                    min={defaultStoneCarrat[0]}
                    onAfterChange={handleChangeStoneCarrat}
                    onChange={handleChangeStoneCarrat}
                    step={0.25}
                    value={stoneCarrat}
                  />
                </Col>
              </Row>
              <Row type="flex">
                <Col
                  flex="auto"
                  lg={{ offset: 6, span: 12 }}
                  className="pd-contact-form-col-input-number">
                  <InputNumber
                    defaultValue={stoneCarrat[0]}
                    max={stoneCarrat[1]}
                    min={defaultStoneCarrat[0]}
                    onChange={handleChangeStoneCarratMin}
                    formatter={carratFormatter}
                    parser={carratParser}
                    value={stoneCarrat[0]}
                  />
                  <InputNumber
                    defaultValue={stoneCarrat[1]}
                    max={defaultStoneCarrat[1]}
                    min={stoneCarrat[0]}
                    onChange={handleChangeStoneCarratMax}
                    formatter={carratFormatter}
                    parser={carratParser}
                    value={stoneCarrat[1]}
                  />
                </Col>
              </Row>
            </div>
            <div className="pd-contact-form-wrapper-slider">
              <Row type="flex">
                <Col flex="auto" lg={{ offset: 6, span: 12 }}>
                  <PdTitle
                    level={3}
                    font="montserrat"
                    bold
                    color="black"
                    size="medium">
                    Price range
                  </PdTitle>
                  <PdSliderRange
                    className="pd-contact-form-slider"
                    defaultValue={defaultPriceRange}
                    max={defaultPriceRange[1]}
                    min={defaultPriceRange[0]}
                    onAfterChange={handleChangePriceRange}
                    onChange={handleChangePriceRange}
                    step={500000}
                    value={priceRange}
                  />
                </Col>
              </Row>
              <Row type="flex">
                <Col
                  flex="auto"
                  lg={{ offset: 6, span: 12 }}
                  className="pd-contact-form-col-input-number">
                  <InputNumber
                    defaultValue={priceRange[0]}
                    max={priceRange[1]}
                    min={defaultPriceRange[0]}
                    onChange={handleChangePriceRangeMin}
                    formatter={rupiahFormatter}
                    parser={currencyParser}
                    value={priceRange[0]}
                  />
                  <InputNumber
                    defaultValue={priceRange[1]}
                    max={defaultPriceRange[1]}
                    min={priceRange[0]}
                    onChange={handleChangePriceRangeMax}
                    formatter={rupiahFormatter}
                    parser={currencyParser}
                    value={priceRange[1]}
                  />
                </Col>
              </Row>
            </div>
            <Row type="flex">
              <Col
                flex="auto"
                lg={{ offset: 6, span: 12 }}
                style={{ display: 'flex', justifyContent: 'center' }}>
                <Upload
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  customRequest={awsUpload}
                  maxCount={1}>
                  <Button>
                    <UploadOutlined type="upload" />
                    {imageURL ? ' Image Uploaded' : ' Choose image for Upload'}
                  </Button>
                </Upload>
              </Col>
            </Row>
            <div className="pd-contact-form-wrapper-tris-input">
              <Row type="flex" gutter={16}>
                <Col flex="auto" lg={{ offset: 6, span: 4 }}>
                  <Form.Item
                    name={FORM_FIELD.NAME}
                    rules={[
                      { message: 'Please insert your name!', required: true },
                    ]}>
                    <Input
                      autoComplete="off"
                      placeholder="Name"
                      prefix={<UserOutlined type="user" />}
                    />
                  </Form.Item>
                </Col>
                <Col flex="auto" lg={{ span: 4 }}>
                  <Form.Item
                    name={FORM_FIELD.EMAIL}
                    rules={[
                      {
                        message: 'Please insert your email!',
                        required: true,
                      },
                    ]}>
                    <Input
                      type="email"
                      placeholder="Email"
                      autoComplete="off"
                      prefix={<MailOutlined type="mail" />}
                    />
                  </Form.Item>
                </Col>
                <Col flex="auto" lg={{ span: 4 }}>
                  <Form.Item
                    name={FORM_FIELD.PHONE}
                    rules={[
                      {
                        message: 'Please insert your phone no!',
                        required: true,
                      },
                    ]}>
                    <Input
                      type="tel"
                      placeholder="Phone"
                      autoComplete="off"
                      prefix={<PhoneOutlined />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className="pd-contact-form-wrapper-input">
              <Row type="flex" gutter={16}>
                <Col flex="auto" lg={{ offset: 6, span: 12 }}>
                  <Form.Item name={FORM_FIELD.DESCRIPTION}>
                    <TextArea rows={4} placeholder="Write something..." />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Row type="flex">
              <Col
                flex="auto"
                lg={{ offset: 6, span: 12 }}
                style={{ display: 'flex', justifyContent: 'center' }}>
                <Button>Submit</Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
}
