import React, { useState, useEffect } from 'react';
import { Row, Col, InputNumber, Select, Typography, Pagination } from 'antd';

import { PdTitle } from 'components/pd-title';
import { PdBreadcrumbs } from 'components/pd-breadcrumbs';
import { PdProductCard } from 'components/pd-product-card';
import { PdSliderRange } from 'components/pd-slider-range';
import { PdCheckableTag } from 'components/pd-checkabletag';

import { rupiahFormatter } from 'utils/numberFormatter';
import { currencyParser } from 'utils/numberParser';

import {
  diamondData,
  diamondCut,
  diamondClarity,
  diamondTypes,
  diamondColour,
} from './mockData';

import './style.scss';

const { Option } = Select;
const { Text, Title } = Typography;
const defaultPriceRange = [1000000, 50000000];
const defaultCarratRange = [1.0, 30.0];
const defaultIntensity = [0, 1, 2, 3, 4, 5];
export const dotsStyle = {
  height: 0,
  width: 0,
  border: 0,
};

export function DiamondColor(props) {
  const { history } = props;
  // eslint-disable-next-line no-unused-vars
  const [sort, setSort] = useState('best_seller');
  const [cursor, setCursor] = useState(1);
  const [content, setContent] = useState([]);
  const [diamondFilter, setDiamondFilter] = useState({
    shape: '',
    cut: [1, Object.keys(diamondCut).length],
    color: '',
    clarity: [1, Object.keys(diamondClarity).length],
    carrat: defaultCarratRange,
    price: defaultPriceRange,
    intensity: 0,
  });

  const [slideRange, setSlideRange] = useState({
    carrat: defaultCarratRange,
    price: defaultPriceRange,
  });

  useEffect(() => {
    fetchRings({ cursor });
  }, [cursor]);

  function handleChangeFilter(field) {
    return (value) => {
      setDiamondFilter((prev) => ({ ...prev, [field]: value }));
    };
  }

  function handleTagCheck(value, field) {
    return () => handleChangeFilter(field)(value);
  }

  function handleChangeSlideRange(value = 0, field, type) {
    if (value !== '') {
      const changedValue =
        type === 'min'
          ? [value, slideRange[field][1]]
          : [slideRange[field][0], value];
      setSlideRange((prev) => ({ ...prev, [field]: changedValue }));
    }
  }

  const sortFilter = {
    '': 'Most Suitable',
    best_seller: 'Best Seller',
    price: 'Price',
    name: 'Name',
  };

  function handleSelectSort(value) {
    setSort(value);
  }

  function fetchRings({ cursor = 1, limit = 9 }) {
    const max = cursor * limit;
    const min = max - limit;
    setContent(diamondData.slice(min, max));
  }

  function handleDetailPage(id = 1) {
    history.push(`/diamonds-colors/${id}`);
  }

  return (
    <div className="pd-diamond-color">
      <div className="pd-diamond-color-filter">
        <Row>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
            <div className="pd-diamond-color-filter-title">
              <PdTitle size="medium" font="montserrat" color="black" bold>
                Diamond
              </PdTitle>
            </div>
            <PdBreadcrumbs
              className="pd-diamond-color-filter-breadcrumb"
              contents={['Home', 'Diamond', 'Oval']}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 22, offset: 1 }}
            lg={{ span: 20, offset: 2 }}
            className="pd-checkabletags">
            <div className="pd-diamond-color-filter-subtitle">
              <PdTitle
                color="black"
                bold
                size="extra-small"
                font="montserrat"
                level={2}>
                Color
              </PdTitle>
            </div>
            {diamondColour.map((key) => (
              <PdCheckableTag
                key={key.color}
                checked={diamondFilter.color === key.color.toLowerCase()}
                onChange={handleTagCheck(key.color.toLowerCase(), 'color')}
                style={{ textAlign: 'center' }}>
                <Row>
                  <Col>
                    <img
                      src={key.img}
                      style={{ maxWidth: '24px', maxHeight: '24px' }}
                      alt=""
                    />
                  </Col>
                  <Col>
                    <Text>{key.color}</Text>
                  </Col>
                </Row>
              </PdCheckableTag>
            ))}
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 22, offset: 1 }}
            lg={{ span: 9, offset: 2 }}
            className="pd-checkabletags">
            <div className="pd-diamond-color-filter-subtitle">
              <PdTitle
                color="black"
                bold
                size="extra-small"
                font="montserrat"
                level={2}>
                Shape
              </PdTitle>
            </div>
            {diamondTypes.map((key) => (
              <PdCheckableTag
                key={key.name}
                checked={diamondFilter.shape.indexOf(key.name) > -1}
                onChange={handleTagCheck(key.name, 'shape')}
                style={{ textAlign: 'center' }}>
                <Row type="flex" justify="center">
                  <Col>
                    <img src={key.img} style={{ maxWidth: 24 }} alt="" />
                  </Col>
                </Row>
              </PdCheckableTag>
            ))}
          </Col>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 9, offset: 2 }}>
            <div>
              <PdTitle
                color="black"
                bold
                size="extra-small"
                font="montserrat"
                level={2}>
                Cut
              </PdTitle>
            </div>
            <PdSliderRange
              className="pd-diamond-color-slider"
              marks={diamondCut}
              step={null}
              min={1}
              max={Object.keys(diamondCut).length}
              tooltipVisible={false}
              onChange={handleChangeFilter('cut')}
              dotStyle={dotsStyle}
              value={diamondFilter.cut}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 22, offset: 1 }}
            lg={{ span: 9, offset: 2 }}
            className="pd-checkabletags">
            <div>
              <PdTitle
                color="black"
                bold
                size="extra-small"
                font="montserrat"
                level={2}>
                Intensity
              </PdTitle>
            </div>
            <div className="pd-diamond-color-intensity">
              {defaultIntensity.map((key) => (
                <PdCheckableTag
                  key={key}
                  checked={diamondFilter.intensity === key}
                  onChange={handleTagCheck(key, 'intensity')}
                  className="checkable"
                  style={{ textAlign: 'center' }}>
                  <Row className="background-intensity">
                    <div style={{}} className="darken-intensity"></div>
                  </Row>
                </PdCheckableTag>
              ))}
            </div>
          </Col>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 9, offset: 2 }}>
            <div>
              <PdTitle
                color="black"
                bold
                size="extra-small"
                font="montserrat"
                level={2}>
                Carrat
              </PdTitle>
            </div>
            <PdSliderRange
              className="pd-diamond-color-slider"
              defaultValue={defaultCarratRange}
              max={slideRange.carrat[1]}
              min={slideRange.carrat[0]}
              onAfterChange={handleChangeFilter('carrat')}
              onChange={handleChangeFilter('carrat')}
              step={0.1}
              value={diamondFilter.carrat}
            />
            <Row>
              <Col
                lg={{ span: 24 }}
                className="pd-diamond-color-col-input-number">
                <InputNumber
                  style={{ width: 112 }}
                  defaultValue={slideRange.carrat[0]}
                  max={slideRange.carrat[1]}
                  min={slideRange.carrat[0]}
                  onChange={(value) =>
                    handleChangeSlideRange(value, 'carrat', 'min')
                  }
                  value={slideRange.carrat[0]}
                />
                <InputNumber
                  style={{ width: 112 }}
                  defaultValue={slideRange.carrat[1]}
                  max={slideRange.carrat[1]}
                  min={slideRange.carrat[0]}
                  onChange={(value) =>
                    handleChangeSlideRange(value, 'carrat', 'max')
                  }
                  value={slideRange.carrat[1]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 9, offset: 2 }}>
            <div>
              <PdTitle
                color="black"
                bold
                size="extra-small"
                font="montserrat"
                level={2}>
                Clarity
              </PdTitle>
            </div>
            <PdSliderRange
              className="pd-diamond-color-slider"
              marks={diamondClarity}
              step={null}
              min={1}
              max={Object.keys(diamondClarity).length}
              tooltipVisible={false}
              onChange={handleChangeFilter('clarity')}
              dotStyle={dotsStyle}
              value={diamondFilter.clarity}
            />
          </Col>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 9, offset: 2 }}>
            <div>
              <PdTitle
                color="black"
                bold
                size="extra-small"
                font="montserrat"
                level={2}>
                Price
              </PdTitle>
            </div>
            <PdSliderRange
              className="pd-diamond-color-slider"
              defaultValue={defaultPriceRange}
              max={slideRange.price[1]}
              min={slideRange.price[0]}
              onAfterChange={handleChangeFilter('price')}
              onChange={handleChangeFilter('price')}
              step={500000}
              value={diamondFilter.price}
            />
            <Row>
              <Col
                lg={{ span: 24 }}
                className="pd-diamond-color-col-input-number">
                <InputNumber
                  style={{ width: 112 }}
                  defaultValue={slideRange.price[0]}
                  max={slideRange.price[1]}
                  min={slideRange.price[0]}
                  onChange={(value) =>
                    handleChangeSlideRange(value, 'price', 'min')
                  }
                  formatter={rupiahFormatter}
                  parser={currencyParser}
                  value={slideRange.price[0]}
                />
                <InputNumber
                  style={{ width: 112 }}
                  defaultValue={slideRange.price[1]}
                  max={slideRange.price[1]}
                  min={slideRange.price[0]}
                  onChange={(value) =>
                    handleChangeSlideRange(value, 'price', 'max')
                  }
                  formatter={rupiahFormatter}
                  parser={currencyParser}
                  value={slideRange.price[1]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div className="">
        <Row type="flex" align="middle">
          <Col xs={{ span: 9, offset: 2 }}>
            <div>
              <PdTitle
                bold
                color="black"
                font="montserrat"
                level={2}
                size="small">
                Showing 12 result
              </PdTitle>
            </div>
          </Col>
          <Col xs={{ span: 9, offset: 2 }}>
            <div className="pd-diamond-color-content-wrapper-filter">
              Sort by
              <Select
                className="pd-margin-left-sm"
                defaultValue={undefined}
                onChange={handleSelectSort}
                placeholder="Choose Filter"
                style={{ width: 126 }}>
                {Object.keys(sortFilter).map((key) => (
                  <Option key={key} value={key}>
                    {sortFilter[key]}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
        <Row style={{ margin: '0 8.33333333%' }} gutter={[16, 16]}>
          {content.map((ring) => {
            const { name = '', price = 0 } = ring ?? {};
            return (
              <Col xs={12} lg={6} key={name} className="pd-product-card">
                <PdProductCard hoverable onClick={() => handleDetailPage(1)}>
                  <img
                    src={ring.image}
                    alt="name"
                    className="pd-diamond-color-content-card-image"
                  />
                  <Title
                    level={4}
                    className="pd-diamond-color-content-card-title">
                    {name}
                  </Title>
                  <Text>{rupiahFormatter(price)}</Text>
                </PdProductCard>
              </Col>
            );
          })}
        </Row>
        <Row className="pd-margin-top-md" gutter={10}>
          <Col lg={{ span: 20, offset: 2 }}>
            <Pagination
              defaultCurrent={cursor}
              total={diamondData.length}
              onChange={setCursor}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default { DiamondColor };
