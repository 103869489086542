import React from 'react';
import { Modal } from 'antd';

import './style.scss';

export function PdModal({ className, children, ...props }) {
  return (
    <Modal className={`pd-modal ${className}`} {...props}>
      {children}
    </Modal>
  );
}

export default { PdModal };
