import axios from 'axios';

export const createAPI = (baseURL = '/', config = {}) => {
  const axiosInstance = axios.create({
    baseURL,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...config,
  });

  // setup axios.intercept
  return axiosInstance;
};

export const api = createAPI(`${process.env.REACT_APP_API_URL}/v1`);

export default api;
